// module
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { GetUserForm, NumberFormat } from "../../services/srvc-utilities";

import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";

import { AssetsDetails, UnitsDetails } from "../../services/srvc-assets-realm";
import {
  AssetsTransferClaim,
  AssetsTransferCreate,
  AssetsTransferSubmit,
} from "../../services/srvc-transfers-assets-realm";

export default function AssetsMintModule() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [form, setForm] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState("0%")

  const [timeLeft, setTimeLeft] = useState(30); // Initialize countdown to 30 seconds
  const [modalVisible, setModalVisible] = useState(false);
  const [disableUI, setDisableUI] = useState(false);
  const [steps, setSteps] = useState('')
  const [memo, setMemo] = useState("");
  const [assetx, setAssetx] = useState({});
  const [unitx, setUnitx] = useState()
  const [data, setData] = useState({
    sale: "",
    rate: "",
  });

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        var res = await UnitsDetails({data: {item:asset.item}, srvc:''})
        setUnitx(res.data.item)
        res = await AssetsDetails({ data: { item: asset.item }, srvc: "" });
        if (res.stat) {
          setAssetx(res.data.item);
        }


        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

  // useEffect for form validation
  useEffect(() => {
    setForm(false);
    if (data.size !== "" && data.number !== "") setForm(true);
  }, [data]);

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val });
  };

  const handleSubmit = async () => {
    // setLoader(true);
    setSubmit(true);
    // setModalVisible(true)
    setDisableUI(true)
    var datx = {
      "credit": {"item": asset.item, "role": "team"},
      "debit": {"item": "", "role": ""},
      "asset": {"item": asset.item, "chain": ""},
      "unit": {"item": unitx, "chain": ""},
      "init":true,
      "sale": { "number": data.sale, "ticker": "STX" },
      "rate": { "number": data.rate, "ticker": "INR" },
      "meta": {"name":"asset claim", "memo": ""}
    }

    setMemo("processing step 0/3")

    setTimeout(()=>{
      setMemo("processing step 1/3")
      setDone("33%")
    },3000)
 
    var result = await AssetsTransferClaim({data:datx, srvc:''})
    // console.log(result)
    if (result.stat) {
      setMemo("processing step 2/3")
      setDone("67%")
      datx.meta={name:'asset transfer', memo:'asset transfer'}
      var result = await AssetsTransferCreate({ data: datx, srvc: "" });
      if(result.stat)
      {  
        var result = await AssetsTransferSubmit({data:{item:result.data.item, status:"1"}})
        if(result.stat)
        {
          setTimeout(()=>{
            setLoader(false);
            setMemo("Completed step 3/3")
            setDone("100%")
            setDisableUI(false)
            navigate(`/team/home`)
          },12000)
        }
        
      }
     
    } else {
      setDisableUI(false)
      setLoader(false);
      setMemo("Error: Transfer failed !");
    }
  };






  if (loader) {
    return <> Please Wait... </>;
  }

  return (
    <div className="">
      {/* info */}
      <p className="m-0 mx-3">
        Please Enter Number of Share Units and Rate per Unit
      </p>
      <WebbDividerMedium />

      {/* data */}
      <div className="mx-3">
        <div className="form-group mb-3">
          <label className="form-label small">
            Number of Units <FormNeeded />
          </label>
          <input
            type="text"
            className="form-control height-md"
            style={{ fontSize: "0.9rem", height: "2.7rem" }}
            value={data.sale}
            onChange={({ target }) => {
              handleChange("sale", target.value);
            }}
            placeholder="0123"
          ></input>
        </div>

        <div className="form-group mb-3">
          <label className="form-label small">
            Rate Per Unit <FormNeeded />
          </label>
          <input
            type="text"
            className="form-control height-md"
            style={{ fontSize: "0.9rem", height: "2.7rem" }}
            value={data.rate}
            onChange={({ target }) => {
              handleChange("rate", target.value);
            }}
            placeholder="0123"
          ></input>
        </div>
      </div>

      <WebbDividerMedium />
      <div className="mx-3">
        {/* <p className="m-0">{memo}</p> */}
      </div>

      <WebbDividerMedium />
      <div className={submit ? "d-none" : "mx-3"}>
        <WebbDividerMedium />
        <div className="d-flex justify-content-between">
          <button
            className={`btn btn-light border back-color-wite rounded-xx text-small`}
            type="button"
            disabled={loader || submit}
            onClick={() => {
              navigate("/auth/next");
            }}
          >
            {loader ? "Please Wait..." : "Cancel"}
          </button>

          <button
            className={`btn btn-success border-none rounded-xx text-small`}
            disabled={loader || submit || !data.sale || !data.rate }
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal1"
            // onClick={()=>handleSubmit()}
            onClick={() =>  { handleSubmit(); setModalVisible(true)   }  }
           
          >
            {loader ? "Please Wait..." : "Continue"}
          </button>
        </div>
      </div>

      <WebbDividerMedium />
      <WebbDividerMedium />

      {/* Modal */}
      <div
        className="modal fade rounded-xd"
        id="exampleModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
        data-bs-backdrop="static" // Prevent closing when clicking outside the modal
        data-bs-keyboard="false" // Prevent closing with the Escape key
        onShow={() => setModalVisible(true)}
        onHide={() => setModalVisible(false)}
      >
        <div className="modal-dialog">
          <div className="modal-content w-100">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Processing... </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setModalVisible(false)}
                disabled={disableUI} // Disable close button during countdown
              ></button>
            </div>
            <div className="modal-body">
              {/* Countdown Timer */}
              <p> Unit creation is being processed. Please wait for few seconds. </p>
              <p className="mb-3 mt-2 text-primary ">{memo}</p>
              <div className="progress">
              <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{width: done }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setModalVisible(false)}
                disabled={disableUI} // Disable cancel button during countdown
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <WebbDividerMedium />
      <WebbDividerMedium />
    </div>
  );
}
