// documents
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";


import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

// import listDocs from '../../data.static/data-documents.json'
import list from '../../data.static/data-documents-user.json'
import {  UserDocumentList } from "../../services/srvc-media-docs-realm";


export default function UserDocsListModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState(list.data.filter(x=>x.actv))


  const updateObjectByKeyValue = (array, key, value, updatedProperties) => {
    return array.map(obj =>
      obj[key] === value ? { ...obj, ...updatedProperties } : obj
    );
  };


  useEffect( () => {
    if (asset){
      
      const fetchData = async() => {
        
        setLoader(true);
        
       
        const result = await UserDocumentList({data: {user: asset.item, format:"kycdoc"}})
        
        if (result.stat) {
          var list = result.data.list
         
          for(var i=0; i<list.length ; i++)
          {
            var key = list[i].sort
            var updates = {active: true, data: {status: true, number: list[i].number}}
           
            setData(data => 
                  data.map(obj => 
                    obj["code"] === key 
                      ? { ...obj, ...updates } 
                      : obj
                  )
                );   
          }
        }
        setLoader(false);
        
      }
      fetchData()
    } else {}
  },[]);
  


  const handleClick = (item) => {
    
    if(item.active) window.open(`/${asset.role}/${item.url}`)
    else navigate(`/${asset.role}/w/docs/${item.link}`)
    
  }


  if (loader) return <><div className="mx-3 text-color-tone">Please Wait...</div></>
  if (!loader && data && data.length === 0) return <>
    <div className="text-color-tone">No Documents Linked</div>
  </>

  return (
  <>
    {/* data */}
    <div className="back-color-wite rounded-xd border">
    {data && data.map((item, i) => (
      <div key={i}>
      <div className={`d-flex px-3 mt-3 mb-3`} >

        <div className="">
          <p className="m-0 ">
          <i className={`bx bxs-${item.active ? 'check' : 'error'}-circle ${item.active ? 'text-color-success' : 'text-color-error'}`}></i>
          </p>
        </div>

        <div className="ms-2">       
          <p className="m-0 ">
            <span className="text-bold">{item?.meta?.name || '******'}</span>
          </p>
          <p className="m-0 text-sm">
            <span className="">Number: {item?.data?.number ||'******'}</span>
          </p>
          <p className="text-small m-0 text-sm d-none">
            <span className="">Verified: {item.active ? 'Yes' : 'No'}</span>
          </p>          
        </div>
    
        <div className="ms-auto text-end text-color-wite">      
          <div className="mb-1"></div>
          <span className={`p-2 px-3 back-color-success rounded-xx text-small align-middle ${item.active ? '': 'd-none'}`} 
            >
            {'Verified'}
          </span>           
          <span className={`p-2 px-3 back-color-error rounded-xx text-small align-middle cursor hidark   ${item.active ? 'd-none': ''}`} 
            onClick={() => handleClick(item)}>
            {'Pending'}
          </span>   
          
        </div>

      </div>
      <div className={i < data.length-1 ? 'border-bottom': ''}></div>
      </div>
    ))}
    </div> 

  </>

  )
}