
import { useNavigate } from 'react-router-dom';

export default function BackButton () {

  const navigate = useNavigate()

  

  return (
  <>
    <button className='btn btn-sm text-center m-0 p-0 align-middle' onClick={()=> navigate(-1)} style={{width:'40px', height:'30px'}}>
      <i class='bi bi-chevron-left' ></i>
    </button>

  </>
  );
}