// routes
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../context/authcontext'

// views - main
import Main from '../views.xz/main-xz'

// views - home
import HomeUser from '../views.home/home-user'
import HomeTeam from '../views.home/home-team'


// views - shares
import AssetsListUser from '../views.assets/assets-list-user'
import AssetsTableTeam from '../views.assets/assets-table-team'
import AssetsActivities from '../views.assets/assets-activities'

import AssetsInit from '../views.assets/assets-init'
import AssetsMint from '../views.assets/assets-mint'
import AssetsNew from '../views.assets/assets-new'
import AssetsTransfer from '../views.assets/assets-transfer'
import AssetsRevoke from '../views.assets/assets-revoke'


// views - vault
import Vault from '../views.vault/vault'
import VaultFinance from '../views.vault/vault-finance'
import VaultContracts from '../views.vault/vault-contracts'
import VaultOthers from '../views.vault/vault-others'

import NewDocumentView from '../views.document/document-new'
import NewContractView from '../views.contracts/contract-new-info'
import ContractView from '../views.contracts/contract-view'
import ContractAddDocumentView from '../views.contracts/contract-add-file'
import ContractsEditMembersView from '../views.contracts/contracts-edit-mmbr'
import ContractDocumentView from '../views.contracts/contract-document-view'


// views - network
import Network from '../views.network/network'
import NetworkBoard from '../views.network/network-board'
import NetworkMentors from '../views.network/network-mentors'
import NetworkTeam from '../views.network/network-team'
import NetworkInvestors from '../views.network/network-investors'
import NetworkAccounts from '../views.network/network-accounts'
import NetworkLaw from '../views.network/network-law'
import NetworkOthers from '../views.network/network-others'


// views - network
import Rooms from '../views.rooms/rooms'


// views - transfers
import Transfers from "../views.transfers/transfers";


// views - user
import UserProfile from '../views.user/user-main'
import UserDocuments from '../views.user/user-docs'
import UserAccounts from '../views.user/user-accounts'
import UserTeams from '../views.user/user-teams'

import UserDocsIdentitiCreate from '../views.user/user-docs-ssid-create'
import UserDocsTaxxCreate from '../views.user/user-docs-taxx-create'


// views - onboard
import UserOnboardName from '../views.onboard/user-onboard-name'
import UserOnboardIdentiti from '../views.onboard/user-onboard-id'
import UserOnboardMinter from '../views.onboard/user-onboard-minter'
import UserOnboardHold from '../views.onboard/user-onboard-hold'

// views - auth
import AuthMailCode from '../views.auth/auth-mail-code'
import AuthNext from '../views.auth/auth-next'
import AuthSessionX from '../views.auth/auth-session-x'
import UserNetworkAddView from '../views.network/network-add'
import TransfersAccountCredit from '../views.transfers/transfers-account-credit'
import { TransfersAccountDebit } from '../services/srvc-transfers-realm'
import TransfersSatement from "../views.transfers/transfers-statement";
import Contract from '../views.contract/contract'
import NewTeamCreateView from '../views.user/user-team-create'
import MailCreate from '../views.network/network-mail-create'
import TeamOnboardBasicInfoView from '../views.onboard/team-onboard-basic-info'
import TeamOnboardContactInfoView from '../views.onboard/team-onboard-contact-info'
import TeamOnboardLegalInfoView from '../views.onboard/team-onboard-legal-info'
import TeamOnboardMemberInfoView from '../views.onboard/team-onboard-member-info'
import VaultCertificates from '../views.vault/vault-certificate'
import AssetsOwnedDetailsView from '../views.assets/assets-owned-details'
import OffersDetailsView from '../views.assets/assets-offers-details'
import AssetsOwnedDetailsAdminView from '../views.assets/assets-owned-details-admin'




const routes = [
  { route: "/", content: <Main />, auth: false },

  // home
  { route: "/user/home", content: <HomeUser />, auth: false },
  { route: "/team/home", content:  <AssetsTableTeam />, auth: false }, // <HomeTeam />

  // shares
  { route: "/user/shares/folio", content: <AssetsListUser />, auth: false },
  {
    route: "/user/shares/activities",
    content: <AssetsActivities />,
    auth: false,
  },

  { route: "/team/shares/table", content: <AssetsTableTeam />, auth: false },
  {
    route: "/team/shares/activities",
    content: <AssetsActivities />,
    auth: false,
  },

  { route: "/team/shares/init", content: <AssetsInit />, auth: false },
  { route: "/team/shares/mint", content: <AssetsMint />, auth: false },
  { route: "/team/shares/new", content: <AssetsNew />, auth: false },
  { route: "/team/shares/transfer", content: <AssetsTransfer />, auth: false },
  { route: "/team/shares/revoke", content: <AssetsRevoke />, auth: false },


  // contracts
  { route: "/user/contract", content: <Contract />, auth: false },


  // Assets
  { route: "/user/asset/owned/:id", content: <AssetsOwnedDetailsView />, auth: false },
  { route: "/user/asset/admin/:id", content: <AssetsOwnedDetailsAdminView />, auth: false },

  { route: "/team/asset/owned/:id", content: <AssetsOwnedDetailsView />, auth: false },
  

  // offers
  { route: "/user/offer/:id", content: <OffersDetailsView/>, auth: false },

  { route: "/team/offer/:id", content: <OffersDetailsView/>, auth: false },



  // vault
  { route: "/user/vault", content: <Vault />, auth: false },
  // { route: "/user/vault/codocs", content: <Vault />, auth: false },
  { route: "/user/vault/finance", content: <VaultFinance />, auth: false },
  { route: "/user/vault/contracts", content: <VaultContracts />, auth: false },
  { route: "/user/vault/others", content: <VaultOthers />, auth: false },
  { route: "/user/vault/certificates", content: <VaultCertificates />, auth: false },

  
  { route: "/team/vault", content: <Vault />, auth: false },
  { route: "/team/vault/codocs", content: <Vault />, auth: false },
  { route: "/team/vault/finance", content: <VaultFinance />, auth: false },
  { route: "/team/vault/contracts", content: <VaultContracts />, auth: false },
  { route: "/team/vault/others", content: <VaultOthers />, auth: false },
  { route: "/team/vault/certificates", content: <VaultCertificates />, auth: false },

  // documents
  { route: "/user/new/document", content: <NewDocumentView />, auth: false },
  { route: "/team/new/document", content: <NewDocumentView />, auth: false },

  // Contracts

  { route: "/user/new/contract", content: <NewContractView />, auth: false },
  { route: "/team/new/contract", content: <NewContractView />, auth: false },

  { route: "/user/contract/:id", content: <ContractView />, auth: false },
  { route: "/team/contract/:id", content: <ContractView />, auth: false },

  { route: "/user/contract/add/file/:id", content: <ContractAddDocumentView />, auth: false, },
  { route: "/team/contract/add/file/:id", content: <ContractAddDocumentView />, auth: false, },

  {
    route: "/user/contract/members/:id",
    content: <ContractsEditMembersView />,
    auth: false,
  },
  {
    route: "/team/contract/members/:id",
    content: <ContractsEditMembersView />,
    auth: false,
  },

  {
    route: "/user/contract/document/:id",
    content: <ContractDocumentView />,
    auth: false,
  },
  {
    route: "/team/contract/document/:id",
    content: <ContractDocumentView />,
    auth: false,
  },

  // network
  { route: "/user/network/founders", content: <Network />, auth: false },
  { route: "/user/network/board", content: <NetworkBoard />, auth: false },
  { route: "/user/network/mentors", content: <NetworkMentors />, auth: false },
  { route: "/user/network/team", content: <NetworkTeam />, auth: false },
  {
    route: "/user/network/investors",
    content: <NetworkInvestors />,
    auth: false,
  },
  {
    route: "/user/network/accounts",
    content: <NetworkAccounts />,
    auth: false,
  },
  { route: "/user/network/law", content: <NetworkLaw />, auth: false },
  { route: "/user/network/others", content: <NetworkOthers />, auth: false },
  { route: "/user/network/add", content: <UserNetworkAddView />, auth: false },

  { route: "/team/network/founders", content: <Network />, auth: false },
  { route: "/team/network/board", content: <NetworkBoard />, auth: false },
  { route: "/team/network/mentors", content: <NetworkMentors />, auth: false },
  { route: "/team/network/team", content: <NetworkTeam />, auth: false },
  { route: '/team/network/mail', content: <MailCreate />, auth: false },
  {
    route: "/team/network/investors",
    content: <NetworkInvestors />,
    auth: false,
  },
  {
    route: "/team/network/accounts",
    content: <NetworkAccounts />,
    auth: false,
  },
  { route: "/team/network/law", content: <NetworkLaw />, auth: false },
  { route: "/team/network/others", content: <NetworkOthers />, auth: false },
  { route: "/team/network/add", content: <UserNetworkAddView />, auth: false },

  // rooms
  { route: "/user/rooms", content: <Rooms />, auth: false },
  { route: "/team/rooms", content: <Rooms />, auth: false },

  // transfers
  { route: "/user/transfers", content: <Transfers />, auth: true },
  { route: "/team/transfers", content: <Transfers />, auth: true },
  {
    route: "/user/transfers/statement",
    content: <TransfersSatement />,
    auth: true,
  },
  {
    route: "/team/transfers/statement",
    content: <TransfersSatement />,
    auth: true,
  },

  // transfers - funds
  {
    route: "/user/account/credit",
    content: <TransfersAccountCredit />,
    auth: true,
  },
  {
    route: "/user/account/debit",
    content: <TransfersAccountDebit />,
    auth: true,
  },
  {
    route: "/team/account/credit",
    content: <TransfersAccountCredit />,
    auth: true,
  },
  {
    route: "/team/account/debit",
    content: <TransfersAccountDebit />,
    auth: true,
  },

  // accounts

  // user
  { route: "/user/w/main", content: <UserProfile />, auth: false },
  { route: "/user/w/docs", content: <UserDocuments />, auth: false },
  { route: "/user/w/accounts", content: <UserAccounts />, auth: false },
  { route: "/user/w/teams", content: <UserTeams />, auth: false },

  // teams create
  { route: "/user/team/create", content: <NewTeamCreateView />, auth: false },
  { route: "/user/team/onboard/basic/:id", content: <TeamOnboardBasicInfoView />, auth: false },
  { route: "/user/team/onboard/contact/:id", content: <TeamOnboardContactInfoView />, auth: false },
  { route: "/user/team/onboard/legal/:id", content: <TeamOnboardLegalInfoView />, auth: false },
  { route: "/user/team/onboard/member/:id", content: <TeamOnboardMemberInfoView />, auth: false },

  {
    route: "/user/w/docs/taxx/create",
    content: <UserDocsTaxxCreate />,
    auth: false,
  },
  {
    route: "/user/w/docs/ssid/create",
    content: <UserDocsIdentitiCreate />,
    auth: false,
  },

  { route: "/team/w/main", content: <UserProfile />, auth: false },
  { route: "/team/w/docs", content: <UserDocuments />, auth: false },
  { route: "/team/w/accounts", content: <UserAccounts />, auth: false },

  {
    route: "/team/w/docs/taxx/create",
    content: <UserDocsTaxxCreate />,
    auth: false,
  },
  {
    route: "/team/w/docs/ssid/create",
    content: <UserDocsIdentitiCreate />,
    auth: false,
  },

  // onboard
  { route: "/user/onboard", content: <UserOnboardName />, auth: true },
  { route: "/user/onboard/id", content: <UserOnboardIdentiti />, auth: true },
  { route: "/user/onboard/minter", content: <UserOnboardMinter />, auth: true },
  { route: "/user/onboard/hold", content: <UserOnboardHold />, auth: true },

  // auth
  { route: "/auth", content: <AuthMailCode />, auth: false },
  { route: "/auth/next", content: <AuthNext />, auth: true },
  { route: "/auth/x", content: <AuthSessionX />, auth: true },
];

export default function RouteX () {
  const { user } = useAuth()
  // console.log (user)

  return (
    <Routes>
      {routes.map((item, i) =>
        item.auth ? (
          <Route
            key={i}
            path={item.route}
            element={!user ? <Navigate to='/' replace /> : item.content}
          />
        ) : (
          <Route key={i} path={item.route} element={item.content} />
        )
      )}
    </Routes>
  )
}
