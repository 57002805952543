// main
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import ContentFormat from "../content/webz/content-format-xv";
import WebbHeader from "../content/webz/webb-header-navs-xv";

import WebbIcon from "../content/webx/webb-icon";
import UserAvatar from "../content/webx/user-avatar";
import WebbDateTime from "../content/webx/webb-datetime";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import HeaderSectionDark from "../content/webx/head-section-dark";

import { GetLocalBusiness, GetLocalUser } from "../services/srvc-auth-local";
import { GetUserForm } from "../services/srvc-utilities";

import SharesSelectModule from "../content/shares/shares-select";
import SharesSelectMobileModule from "../content/shares/share-select-mobile";
import SharesUserActionsModule from "../content/shares/shares-actions-user";

import MenuButtons from "../content/webx/menu-buttons";
import WebbMenuMobile from "../content/webz/webb-menu-xv";
import TeamInfocardHomeModule from "../content/user/team-infocard-home";
import FloatButton from "../content/float/float-button";

import SharesChartLineModule from "../content/shares/shares-chart-line";
import SharesValuationCardModule from "../content/shares/shares-valuation-card";
import SharesTableTeamModule from "../content/shares/shares-table-team";
import { UnitsDetails } from "../services/srvc-assets-realm";
import WebbSpinText from "../content/webx/webb-spintext";
import WebbLoaderMedium from "../content/webx/webb-loader-md";

export default function AssetsTableTeam() {
  const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();
  const [loader, setLoader] = useState(true);
  const [status, setStatus] = useState(false);
  const [unit, setUnit] = useState();

  useEffect(() => {
    setLoader(true);
    const fetchdata = async () => {
      var res = await UnitsDetails({ data: { item: asset.item }, srvc: "" });
      // console.log(res);
      if (res.stat) {
        setUnit(res.data);
        setStatus(res?.data?.webx?.item == "" ? false : true);
        setLoader(false);
      }
    };
    fetchdata();
  }, []);

  const metadata = {
    name: "Shares",
    banner: {
      link: "https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900",
    },
  };


  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: "xtra", show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`/${asset.role}/home`}>
                <WebbIcon data={{ color: "text-color-main", size: "text-icon-md" }}/>
              </Link>
            </>
          ),
          data: (
            <>
              <WebbHeader />
            </>
          ),
          footer: (
            <>
              <UserAvatar />
            </>
          ),
        }}
        sidebar={{
          header: (
            <>
              {" "}
              <p className="text-normal text-bold m-0">{metadata.name}</p>{" "}
            </>
          ),
          data: (
            <>
              <TeamInfocardHomeModule />
              <SharesSelectModule />
            </>
          ),
          footer: (
            <>
              <WebbDateTime />
            </>
          ),
        }}
        content={{
          header: (
            <>
              <div className="d-flex justify-content-between justify-content-md-start align-items-start">
                <div className="d-flex gap-3">
                  <i
                    data-bs-toggle="offcanvas"
                    href="#offcanvasExample"
                    role="button"
                    aria-controls="offcanvasExample"
                    className="bx bx-menu d-block d-md-none"
                    style={{ fontSize: "25px" }}
                  ></i>
                  <div
                    className="offcanvas offcanvas-start w-75 bg-body-tertiary"
                    tabIndex="-1"
                    id="offcanvasExample"
                    aria-labelledby="offcanvasExampleLabel"
                  >
                    <div className="offcanvas-header">
                      <h6
                        className="offcanvas-title"
                        id="offcanvasExampleLabel"
                      >
                        Contrato
                      </h6>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div
                      className="offcanvas-body d-flex flex-column gap-1"
                      style={{ position: "relative" }}
                    >
                      <TeamInfocardHomeModule />
                      <WebbMenuMobile />
                      <div
                        style={{
                          position: "absolute",
                          bottom: "15px",
                          width: "88%",
                        }}
                      >
                        <MenuButtons />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="m-0">Shares</p>{" "}
                </div>
                <div
                  className="d-block d-md-none"
                  style={{ position: "relative", bottom: "6px" }}
                >
                  <UserAvatar />
                </div>
              </div>
            </>
          ),
          data: (
            <>
              <div className={loader ? "" : "d-none"}>
                <WebbLoaderMedium />
              </div>
              <div className={!loader && status ? "" : "d-none"}>
                <SharesSelectMobileModule />
                <SharesChartLineModule />
                <WebbDividerSmall />
                <SharesValuationCardModule />
                <WebbDividerMedium />
                <SharesTableTeamModule />
              </div>
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <FloatButton action={SharesUserActionsModule} />
            </>
          ),
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: (
            <>
              <SharesUserActionsModule />
            </>
          ),
          footer: <></>,
        }}
      />
    </>
  );
}
