import React from 'react'
import { useNavigate } from 'react-router-dom';
import { GetUserForm } from '../../services/srvc-utilities';
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local';

const BusinessModule = () => {
     const usxx = GetUserForm();
     const usrx = GetLocalUser();
     const temx = GetLocalBusiness();
     const asset = usxx === "user" ? usrx : temx;
    const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/${asset.role}/team/create`)}
      className="cursor d-flex mb-2 px-2 py-1 align-middle align-items-center hilite rounded-wd"
    >
      <i className="bx bx-briefcase text-icon-sm"></i>

      <span className="align-middle ms-2 ">Create Business Account</span>
      <i
        className={`m-0 p-0 bx bx-chevron-right ms-auto`}
        style={{ fontSize: "1.35rem" }}
      ></i>
    </div>
  );
}

export default BusinessModule;