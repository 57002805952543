// shares
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

const list = require("../../data.static/data-select-shares.json").data.filter(
  (item) => item.actv
);

export default function SharesSelectMobileModule(props) {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState([]);
  const [sort, setSort] = useState({ item: location.pathname.split("/")[3] });

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const result = list.filter((x) => x.user.includes(asset.role));
        setData(result);

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

  const handleChange = async (key, val) => {
    setSort({ ...sort, [key]: val });
  };

  const handleSelect = async (sort) => {
    handleCallback(sort);
  };

  const handleCallback = (srtx) => {
    props.item(srtx);
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-4 d-md-none">
        <div className="mx-3">
          {data &&
            data.map((item, i) => (
              <span
                className={item.actv ? "" : "d-none"}
                key={i}
                onClick={() => {
                  setSort({ item: item.name });
                  navigate(`/${usxx}/shares/${item.link}`);
                }}
              >
                <span
                  className={`p-2 px-3 rounded-xx text-small ${
                    sort.item === item.link
                      ? "back-color-success text-color-wite"
                      : "back-color-wite cursor"
                  }`}
                >
                  {item.name}
                </span>
                <span className=" me-md-2 me-0"></span>
              </span>
            ))}
        </div>
      </div>
    </>
  );
}
