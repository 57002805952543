import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TeamsBasicInfoAdd } from "../../services/srvc-team-onboard";

const TeamCreateContactInfo = () => {
  const navigate = useNavigate()
  const {id} = useParams()

  const [submit, setSubmit] = useState(false)
  const [memo, setMemo] = useState('')

  const [values, setValues] = useState({
    registeredAddress: '',
    district:'',
    state: '',
    pincode:'',
    country:'India',
    email: '',
    phoneNumber: '',
    website: '',
  })

  const handleChange = (input) => (e) => {
    setValues({ ...values, [input]: e.target.value });
  };
    const continueStep = async(e) => {
    
    e.preventDefault();
    setMemo('Please Wait...')
    var datx={type:'contact',team: id, values}
    var res = await TeamsBasicInfoAdd({data:datx, srvc:''})
    // console.log(res)
    if(res.stat) navigate(`/user/team/onboard/member/${id}`)
    setMemo(res.memo)

    
      // nextStep();
    };
  
    return (
      <div className="bg-white p-3 border rounded-xd">
        {/* <h6> Contact Information</h6> */}
        <form className="mt-3">
          <label className="mb-1">Registered Address:</label>
          <input type="text" onChange={handleChange('registeredAddress')} value={values.registeredAddress} className="form-control mb-3" />
  

          <label className="mb-1">District :</label>
          <input type="text" onChange={handleChange('district')} value={values.district} className="form-control mb-3" />

          <label className="mb-1">State :</label>
          <input type="text" onChange={handleChange('state')} value={values.state} className="form-control mb-3" />

          <label className="mb-1">PIN Code :</label>
          <input type="text" onChange={handleChange('pincode')} value={values.pincode} className="form-control mb-3" />

          <label className="mb-1">Country :</label>
          <input type="text" onChange={handleChange('country')} value={values.country} className="form-control mb-3" />
  
          <label className="mb-1">Email:</label>
          <input type="email" onChange={handleChange('email')} value={values.email} className="form-control mb-3" />
  
          <label className="mb-1">Phone Number:</label>
          <input type="text" onChange={handleChange('phoneNumber')} value={values.phoneNumber} className="form-control mb-3"/>
  
          <label className="mb-1">Website:</label>
          <input type="text" onChange={handleChange('website')} value={values.website} className="form-control mb-3" />
          
          <p className="text-small text-primary mt-2">{memo}</p>
         
          <button className="btn btn-primary rounded-xx mt-5 float-end" disabled={submit} onClick={continueStep}>Next</button>
        </form>
      </div>
    );
  };
  
  export default TeamCreateContactInfo;
  