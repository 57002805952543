import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { AssetsDetails, UnitsDetails } from "../../services/srvc-assets-realm";
import {
  UnitsStatistics,
  UnitsTokenStatistics,
} from "../../services/srvc-tokens-realm";

export default function SharesValuationCardModule() {
  const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [assx, setAssx] = useState(null);
  const [creatorShare, setCreatorShare] = useState();
  const [tokenList, setTokenList] = useState([]);
  const [shares, setShares] = useState({});

  useEffect(() => {
    if (asset) {
      setLoader(true);
      const fetchData = async () => {
        const res = await UnitsDetails({
          data: { item: asset.item },
          srvc: "",
        });
        if (res.stat) {

          var unitstat = await UnitsStatistics({data: {unit : res.data.item, user: asset.item}, srvc:''})
         
          setAssx(unitstat?.data?.stat?.totalMint || 0)
          const tokenRes = await UnitsTokenStatistics({
            data: { unit: res.data.item, user: asset.item },
            srvc: "",
          });
          // console.log(tokenRes.data.holding)
          setShares(tokenRes.data.holding);
        } else {
          setData([]);
        }

        setLoader(false);
      };
      fetchData();
    }
  }, []);

  const datas = {
    datasets: [
      {
        data: [
          shares?.founderShares,
          shares?.investorShares,
          shares?.mentorsShares,
          shares?.othersShares,
          shares?.creatorShare,
        ],
        backgroundColor: [
          "#007500",
          "#00A300",
          "#00D100",
          "#00FF00",
          "#AAB8C2",
        ],
        hoverBackgroundColor: [
          "green",
          "#76c7c0",
          "#4caf50",
          "#8bc34a",
          "#AAB8C2",
        ],
        borderWidth: 0,
      },
    ],
  };

  const dataz = {
    datasets: [
      {
        data: [100],
        backgroundColor: ["#ECF1F4"],
        hoverBackgroundColor: ["#ECF1F4"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "66%",
    plugins: {
      tooltip: { enabled: false },
      legend: { display: false },
      afterDraw: (chart) => {
        const { ctx, width, height } = chart;
        ctx.restore();
        const fontSize = (height / 114).toFixed(2);
        ctx.font = `${fontSize}em sans-serif`;
        ctx.textBaseline = "middle";
        const text = "54";
        const subText = "Shareholders";
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2 - 10;
        const subTextX = Math.round(
          (width - ctx.measureText(subText).width) / 2
        );
        const subTextY = height / 2 + 10;
        ctx.fillText(text, textX, textY);
        ctx.fillText(subText, subTextX, subTextY);
        ctx.save();
      },
    },
  };

  const segments = [
    { label: "Founders", value: shares?.founderShares || 0, color: "#007500" },
    {
      label: "Investors",
      value: shares?.investorShares || 0,
      color: "#00A300",
    },
    { label: "Advisors", value: shares?.mentorsShares || 0, color: "#00D100" },
    { label: "Others", value: shares?.othersShares || 0, color: "#00FF00" },
    {
      label: "Not Allocated",
      value: shares?.creatorShare || 0,
      color: "#AAB8C2",
    },
  ];

  return (
    assx > 0 && (
      <div
        className="p-3 d-flex flex-lg-row border flex-column w-100 justify-content-between align-items-center rounded-xd"
        style={styles.card}
      >
        <div>
          <div style={styles.header}></div>
          <div style={styles.chartContainer}>
            <Doughnut data={datas} options={options} />
          </div>
        </div>
        <div style={styles.legend}>
          {segments.map((segment, index) => (
            <div key={index} style={styles.legendItem} className="my-3">
              <span>{segment.label}</span>
              <span className="float-end" style={styles.legendPercentage}>
                {segment.value}%
              </span>
              <div className="" style={styles.progressBarContainer}>
                <div
                  style={{
                    ...styles.progressBar,
                    width: `${segment.value}%`,
                    backgroundColor: segment.color,
                  }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  );
}

const styles = {
  card: {
    backgroundColor: "#fff",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
  },
  header: {
    marginBottom: "20px",
  },
  chartContainer: {
    position: "relative",
    width: "240px",
    height: "240px",
    margin: "0 auto",
    marginBottom: "10px",
  },
  legend: {
    textAlign: "left",
  },
  legendItem: {
    marginBottom: "10px",
  },
  legendPercentage: {
    fontWeight: "bold",
  },
  progressBarContainer: {
    backgroundColor: "#ECF1F4",
    borderRadius: "5px",
    overflow: "hidden",
    height: "6px",
    marginTop: "5px",
    width: "300px",
  },
  progressBar: {
    height: "6px",
    borderRadius: "5px 0 0 5px",
  },
};
