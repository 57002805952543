// documents
import { Link, useParams } from "react-router-dom";

import WebbDividerMedium from "./webb-divider-md";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";


export default function DocumentFiles(props) {

  const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();
  const { id } = useParams();
  
  const data = props.data
  // console.log(data)
  // console.log (props.status)

  function DownloadDocx(data) {
    const linkSource = `data:application/pdf;base64,${data.file}`;
    const downloadLink = document.createElement("a");
    const fileName = data.nmbr;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  if (props.status!== 0 && data.length===0) {
    return (
      <> 
        <span>Files in Process</span>
        <WebbDividerMedium/>
      </>
      )    
  } 

  if (data.length === 0) 
  return (
  <> 
    <span> No Files Attached. Please  </span>
    <Link to={`/${asset.role}/documents/new`}>add / upload a file</Link>
    <WebbDividerMedium/>
  </>
  )

  return (
  <>
    <div className={`rounded-wd back-color-wite mb-3 border rounded-xd`}>
    {data && data.length > 0 && data.map((item, i) => (
      <div key={i}>
      <div className="d-flex p-3">
        <div className="me-auto">
          <p className="text-bold m-0 p-0 text-sm">{item.meta.name}</p>
          <p className="m-0 p-0 text-color-tone text-small">ID: {item.item}</p>
          <p className="m-0 p-0 text-color-tone text-sm small">{'Created: '}{(new Date(item.created)).toLocaleString()}</p>
        </div>

        <div className={`text-end py-2`}>
          <a href={item.link} target="_blank">
          <div 
            className="btn btn-sm btn-light border hidark border-none rounded-wd p-1 px-3"
            ><small>Download</small>
          </div>
          </a>
        </div>

      </div>
      
      <div className={`border-bottom ${i < data.length-1 ? '' :'d-none'}`}></div>
      </div>
    ))}
    </div>

    {/* replace option */}
 
  </>
  )
}