// documents
import { Link, useParams } from "react-router-dom";

import WebbDividerSmall from "./webb-divider-sm";
import WebbDividerMedium from "./webb-divider-md";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

const code = require('../../data.static/code-esin.json').data;

export default function DocumentMembers(props) {

  const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();
  const { id } = useParams();
  
  const data = props.data
  // console.log (data)

  if (data.length === 0) 
  return (
  <> 
    <span> No Contacts. Please add</span>
    {/* <Link to={`/${asset.form}/documents/new/xxxx/${id}`}>add / upload a file</Link> */}
    <WebbDividerMedium/>
  </>
  )
// console.log(data)

  return (
  <>
    <div className={`rounded-wd back-color-wite border `}>
    {data && data.length > 0 && data.map((item, i) => (
      <div key={i}>
      
      <div className="d-flex p-3" >
        <div className="">
          <p className="fw-bold m-0 p-0"><span>{i+1}.</span></p>
        </div>

        <div className="ms-2">
          <p className="fw-bold m-0 p-0 text-sm">
            <span>{item.name}</span>
            <span className="ps-2"></span>
            <span className={`p-1 px-2 small rounded-md back-color-tint text-color-tone ${item.item === asset.item ? '' : 'd-none'}`}>
              <small>{'YOU'}</small>
            </span>
          </p>
          <p className="m-0 p-0 text-sm">{item.mail}</p>
        </div>

        <div className={`ms-auto py-2 text-end`}>
          
          <div 
            className={`p-1 px-3 rounded-wd border-none back-color-${code.find(x=> x.code == item.status).colr}`}
            style={{cursor:'auto'}}
          >
            <p className="small m-0 text-white text-uppercase">
              <small> {code.find(x=> x.code == item.status).text}</small>
            </p>
          </div>
          
        </div>


      </div>
      <div className={`border-bottom ${i < data.length-1 ? '' :'d-none'}`}></div>
      </div>
      ))}
    </div>

    <WebbDividerSmall/>
    <p className="text-tone small d-none">
      Note: Please use the Members Option to change the Document Signing Order
    </p>
  </>
  )
}