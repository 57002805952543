// documents
const code = require('../../data.static/code-contracts.json').data

export default function ContractOverview(props) {

  const data = props.data;
  //  console.log (data)
  return (
  <>
    <div className="back-color-wite border rounded-xd">

      <div className="d-flex p-3">
        <div className="me-auto">
          <p className="lead fw-bold text-small m-0 ">{data.name}</p>
          <p className="text-color-main text-small">{data.number}</p>
          <p className="m-0 text-small">Memo: {data.memo}</p>
        </div>
        
        <div className="text-end">
          <div className="d-none"  style={{width:'2rem', height:'2rem'}}>
            <p className={`text-${code.find(item=>parseInt(item.code) == data.status).colr} `}
              style={{fontSize:'2rem', lineHeight:'1rem'}}>
              <i className={code.find(item=>parseInt(item.code) == data.status).icon}></i>
            </p>
          </div>

        </div>

      </div>

      <div className='border-bottom'></div>
      <div className="d-flex p-3">
        <div className="me-auto">
          <p className="text-small">Start Date:</p>   
          <p className="m-0 text-small">End Date:</p>   
        </div>
        <div className="text-end">
          <p className="text-small">{(new Date(data.created_date)).toLocaleString().substring(0,10)}</p>   
          <p className="m-0 text-small">{(new Date(data.end_date)).toLocaleString().substring(0,10)}</p>
        </div>
      </div>

      {/* <hr className='m-0'></hr>
      <div className="d-flex p-3">
        <div className="me-auto">   
          <p className="m-0">Status:</p>   
        </div>
        <div className="text-end">
          <p className="m-0">{code.find(item=>parseInt(item.code) == data.status).text}</p>
        </div>
      </div> */}

    </div>
    

    
  </>
  )
}