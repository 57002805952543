import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { GetUserForm, NumberFormat } from "../../services/srvc-utilities";

import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";

import { AssetsDetails, UnitsCreate } from "../../services/srvc-assets-realm";
import { AssetsTransferClaim, AssetsTransferCreate, AssetsTransferSubmit } from "../../services/srvc-transfers-assets-realm";
import {
  AssetsOffersAccept,
  AssetsOffersCreate,
  AssetsOffersDetails,
  AssetsOffersStatusSet
} from "../../services/srvc-offers-realm";

export default function OffersDetailsModule() {
  const { id } = useParams();

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);

  const [submit, setSubmit] = useState(false);

  const [data, setData] = useState(null);
  const [balance, setBalance] = useState({ number: "", ticker: "STX" });

  const [showModal, setShowModal] = useState(false);

  const [refresh, setRefesh] = useState(false);

  const [sale, setSale] = useState("");
  const [rate, setRate] = useState("");
  const [user, setUser] = useState("");
  const [done, setDone] = useState("0%")
  const [memo, setMemo] = useState("");

  const [disableUI, setDisableUI] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    setLoader(true);
    if (asset) {
      const fetchData = async () => {
        var res = await AssetsOffersDetails({ data: { item: id }, srvc: "" });
        if (res.stat) {
          setData(res.data);
          // console.log(res.data);
        }
        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [refresh]);



  const handleDecline = async () => {
    setMemo("Please Wait ...");
    setSubmit(true);
    var datax = {
      item: id,
      status: 8,
    };

    var res = await AssetsOffersStatusSet({ data: datax, srvc: "" });
    //console.log(res)
    if (res.stat) {
      setMemo("Offer declined successfully.");
      setTimeout(() => {
        navigate(`/${usxx}/home`)
      }, 1000);
    } else {
      setMemo(res.memo);
    }
  };

  const handleAccept = async () => {
    // setLoader(true)
    setSubmit(true)
    setDisableUI(true)


    var res = await AssetsOffersStatusSet({data:{item: id, status:1, active:true }, srvc:''})
    if(res.stat){

      var datx = {
        "credit": {"item": data.user.item, "role": "user"},
        "debit": {"item":data.creator.item, "role": "user"},
        "asset": {"item": data.asset.item, "chain": ""},
        "unit": {"item": data.unit.item, "chain": ""},
        "init": false,
        "sale": { "number": data.sale.nmbr, "ticker": "STX" },
        "rate": { "number": data.rate.rate.nmbr, "ticker": "INR" },
        "meta": {"name":"asset claim", "memo": ""},
        "reference":id
        // "serial": { from: (parseFloat(balance.number) - parseFloat(data.size)).toString(), to:balance.number}
      }
     
      // console.log(datx)
      setMemo("processing step 0/3")
  
      setTimeout(()=>{
        setMemo("processing step 1/3")
        setDone("33%")
      },3000)
   
      var result = await AssetsTransferClaim({data:datx, srvc:''})
      
      setDone("67%")
      setMemo("processing step 2/3")
      if (result.stat) {
        datx.meta={name:'asset transfer', memo:'asset transfer'}
        var result = await AssetsTransferCreate({ data: datx, srvc: "" });
        if(result.stat)
        {
          
          var result = await AssetsTransferSubmit({data:{item:result.data.item, status:"1"}})
          await AssetsOffersStatusSet({data:{item: id, status:6, active:false }, srvc:''})
          setLoader(false);
          setDone("100%")
          setMemo("processing step 3/3")
          setDisableUI(false)
          setTimeout(()=>{
            navigate(`/${usxx}/home`)
          },2000)
        }
       
      } else {
        setLoader(false);
        setMemo("Error: Transfer failed !");
      }

    }
    else{
      setLoader(false);
      setMemo("Error: Transfer failed !");

    }

  
  
  
  }


  if (loader) {
    return (
      <>
        <WebbLoaderSmall />
      </>
    );
  }

  if (! loader && ! data) {
    return (
      <>
        <h6 className="text-center fw-bold  mt-5">Offer Not Exist</h6>
      </>
    );
  }

  return (
    <div className="">
      <div className=" ">
        <div className="rounded-xd back-color-wite">
          <div className="card border-none back-color-none h-100">
            <div className="card-body border-none back-color-none">
              <h5 className="text-lead text-bold m-0">
                {data?.unit?.name || data?.asset?.name || "Asset Name"}
              </h5>
              <p className="text-normal m-0">
                {data?.unit?.memo || data?.asset?.memo || "Asset Details"}
              </p>

              {/* offer details */}
              <div className="mt-3">
                <p className="text-normal m-0 text-bold">
                  Units: {data?.sale?.nmbr || "******"}{" "}
                  {data?.sale?.tick || "******"}
                </p>
                <p className="m-0">
                  Rate: {data?.rate?.rate?.nmbr || "******"}{" "}
                  {data?.rate?.rate?.tick || "******"}
                </p>

              </div>

              {/* Creator Information */}
              <div className="mt-3">
                <p className="text-normal m-0">
                  Seller: {data?.creator?.name || "******"}
                </p>
                <p className="m-0 d-none">
                  Email: {data?.creator?.mail || "******"}
                </p>
              </div>

            </div>

            <div
              className={
                data?.active
                  ? "card-footer back-color-wite border-none back-color-none mb-3"
                  : "d-none"
              }
            >
              <p className="m-0">
                {"Status"}
                {" : "}
                {data?.active ? "Active" : "Inactive"}
              </p>
            </div>
            <p className="mx-3 m-0">{memo}</p>
          </div>
        </div>
      </div>

      <WebbDividerSmall />
      <div className="d-flex mt-3">
        <div className="me-auto">
          <button onClick={()=> handleDecline()} className="btn btn-outline-secondary text-small rounded-xx">
            Decline
          </button>
        </div>
        <div className="text-end">
          <button
            className="btn btn-success text-small rounded-xx"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal1"
            onClick={()=> handleAccept()}
          >
            Accept
          </button>
        </div>
      </div>

      {/* modal */}
    {/* Modal */}
    <div
        className="modal fade rounded-xd"
        id="exampleModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
        data-bs-backdrop="static" // Prevent closing when clicking outside the modal
        data-bs-keyboard="false" // Prevent closing with the Escape key
        onShow={() => setModalVisible(true)}
        onHide={() => setModalVisible(false)}
      >
        <div className="modal-dialog">
          <div className="modal-content w-100">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Processing... </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setModalVisible(false)}
                disabled={disableUI} // Disable close button during countdown
              ></button>
            </div>
            <div className="modal-body">
              {/* Countdown Timer */}
              <p> Offer Claim is being processed. Please wait for few seconds. </p>
              <p className="mb-3 mt-2 text-primary ">{memo}</p>
              <div class="progress">
              <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style={{width: done }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setModalVisible(false)}
                disabled={disableUI} // Disable cancel button during countdown
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}
