// accounts
import * as Realm from 'realm-web';
import axios from "axios";
import services from '../data.services/data-services-main.json'
import { decrypt, encrypt } from './srvc-encr-node';

// const base = (services.data.find(x => x.code == 'assets')).link
const base =
  "https://ap-southeast-1.aws.data.mongodb-api.com/app/contrato-srvc-kcxltrc/endpoint";


export const AssetsDetails = async (item) => {
  const basx = base + "/assets/details";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};


export const AssetsCreate = async (item) => {
  const basx = base + "/assets/create";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};


export const AssetsListUnits = async (item) => {
  const basx = base + "/assets/list/units";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};


export const UnitsCreate = async (item) => {
  const basx = base + "/units/create";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};


export const UnitsDetails = async (item) => {
  const basx = base + "/units/details";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};

export const UnitsMint = async (item) => {
  const basx = base + "/units/mint";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};