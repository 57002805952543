import React from 'react'
import { useState } from 'react';
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import NetworkSelectModule from './network-select';
import HeaderSectionDark from '../webx/head-section-dark';

const NetworkFilterModule = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <HeaderSectionDark data={{ name: "Network" }} />
        <div
          className="d-flex align-items-center d-md-none gap-1 text-small cursor mb-3"
          onClick={handleShow}
        >
          Filter
          <i className="bx bx-slider-alt me-2" style={{ fontSize: "14px" }}></i>
        </div>
      </div>
      <div>
        <Modal className=" ps-5 pt-5" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Network</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <NetworkSelectModule />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default NetworkFilterModule