// documents
import { Link, useNavigate, useParams } from 'react-router-dom';

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

const list = require('../../data.static/list-docs-actions.json').data.filter(item=>item.actv)

export default function DocumentActions(props) {
  
  const asset = GetUserForm() === 'user' ? GetLocalUser() : GetLocalBusiness();
  
  const {id} = useParams();
  const navigate = useNavigate();
  
  const data = props.data.docx;
  // console.log(data)
  const lstx = list.filter(item => item.user.includes(asset.role))
  // var idlist = props.data.idlist

  //  console.log(data)

  var admin = data.user.filter(item=>{
        return item.role=="admin"
  }) 

  // console.log(admin)

  const handleEsin = async() => {
    if (props.data.mmbr ===0) {
      alert('Please add Signing Parties');
    }
    if (props.data.file === 0) {
      alert('Please add Files');
      // history.push(`/${asset.form.substring(0,2)}/documents/esin/location/${id}`);
    }
    if (props.data.mmbr >0 && props.data.file >0) {
      // navigate(`/${asset.role}/contract/esin/location/${id}`);
    }
  }
 
  const handleMembers = async() => {
    navigate(`/${asset.role}/contract/members/${id}`)
  }

  const handleEstm = async() => {
    navigate(`/${asset.role}/contract/add/stamp/${id}`)
  }
  
  const handleStampList= async() => {
    navigate(`/${asset.role}/contract/add/stamp/${id}`)
  }

  const handleFiles = async() => {
    navigate(`/${asset.role}/contract/add/file/${id}`)
  }

  const handleTime = async() => {
    navigate(`/${asset.role}/smart/v/track/${id}`)
  }

  const handleRoom = async() => {
    // console.log ('discuss')
    navigate(`/${asset.role}/rooms/${id}`)
  }

  const handleFeatures = async() => {
    // history.push(`/${asset.form.substring(0,2)}/documents/edit/feat/${id}`)
  }

  const handleArchive = async() => {
    // console.log ('archive')
  }
  
  const handleButton = async(code) => {

    switch(code) {
      case 'esin':
        handleEsin();
        break;
      case 'estm':
        handleEstm();
        break;
      case 'file':
        handleFiles();
        break;
      case 'members':
        handleMembers();
        break;
      case 'estmlist':
        handleStampList();
        break;
      case 'file':
        // handleFiles();
        break;        
      case 'time':
        handleTime();
        break;        
      case 'room':
        handleRoom();
        break;
      case 'feat':
        handleFeatures();
        break;        
      case 'arch':
        handleArchive();
        break;
      default:
        // code block
    }

  }




  return (
    <>
      {/* <div className="back-color-wite rounded-wd border">

        <div className={`row row-cols-5 g-0`} >
        {lstx && lstx.length>0 && lstx.map ((item, i) => 
          <div className="col d-grid "   key={i}  
            style={{pointerEvents: item.stat.includes(data.status) ? "all" : "none"}} >
            <div
              className={`${admin[0].item == asset.item ? "":""} rounded-wd text-center m-2 py-2 hilite text-color-${item.stat.includes(data.status)?"main":"lite"}`}
              onClick={()=> handleButton(item.code)} 
              style={{cursor:item.stat.includes(data.status) ? 'pointer': ''}}
              >
                <i className={item.icon}
                  style={{fontSize:'2rem'}}></i>
                <p className="m-0 p-0 text-color-tone text-sm"><small>{item.name}</small></p>
            </div>
          </div>

        )}
        </div>
  
      </div> */}

  <div className="rounded-wd back-color-wite">
    {lstx && lstx.length>0 && lstx.map ((item, i) => (

      <div onClick={()=> handleButton(item.code)}  className="p-1 border-bottom border-light">

        <div className={`d-flex cursor p-2 hilite rounded-wd ${ i < data.length-1 ? '': ''}`} key={i}
          // onClick={()=>{handleChange('item',item.name); handleCallback(item.name)}}
          // onClick={()=>{navigate(`/${usxx}/vault/${item.link}`)}}
        >
        
          <div className="">
            <span className="text-small">
              <i className={`m-0 p-0 small ${item.icon}`} style={{fontSize:'18px'}}></i>
            </span>
          </div>
          
          <div className="ms-2">
            <p className={`m-0 text-small p-0 text-$'color-next `}>
              {item.name}
            </p>
          </div>

          <div className="ms-auto">
            <i className={`m-0 p-0 text-color-tint bi-chevron-right`}></i>
          </div>
        
        </div>
        <div className="border-bottom border-light d-none"></div>
      </div>
    ))}
    
    </div>
    </>
    )
  }