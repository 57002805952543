import React from "react";
import MenuButtons from "../webx/menu-buttons";
import UserAvatar from "../webx/user-avatar";
import WebbMenuMobile from "../webz/webb-menu-xv";
import TeamInfocardHomeModule from "../user/team-infocard-home";

const MobileSidebarModule = (props) => {
  return (
    <>
      <div className="d-flex justify-content-between justify-content-md-start align-items-start">
        <div className="d-flex gap-3">
          <i
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample"
            className="bx bx-menu d-block d-md-none"
            style={{ fontSize: "25px" }}
          ></i>
          <div
            className="offcanvas offcanvas-start w-75 bg-body-tertiary"
            tabIndex="-1"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div className="offcanvas-header">
              <h6 className="offcanvas-title" id="offcanvasExampleLabel">
                Contrato
              </h6>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div
              className="offcanvas-body d-flex flex-column gap-1"
              style={{ position: "relative" }}
            >
              <TeamInfocardHomeModule />
              {/* <UserInfocardMobileModule /> */}
              {/* <UserAccountBalance /> */}
              <WebbMenuMobile />
              <div
                style={{
                  position: "absolute",
                  bottom: "15px",
                  width: "88%",
                }}
              >
                <MenuButtons />
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="m-0">{props.heading}</p>
        </div>
        <div
          className="d-block d-md-none"
          style={{ position: "relative", bottom: "6px" }}
        >
          <UserAvatar />
        </div>
      </div>
    </>
  );
};

export default MobileSidebarModule;
