import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import { updateTransferData } from "../actions/transferActions";

export default function TransferSearchModule() {
  const usxx = GetUserForm();
  const dispatch = useDispatch();
  const transferData = useSelector((state) => state.transfer);

  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        // Perform any necessary actions
        setLoader(false);
      };
      fetchData();
    }
  }, []);

  const handleCheckboxChange = (key, val) => {
    let newData;

    if (key === "success") {
      newData = {
        ...transferData,
        success: true,
        pending: false,
        failed: false,
        all: false,
        end: "",
        start: "",
        page: 1,
      };
    } else if (key === "pending") {
      newData = {
        ...transferData,
        success: false,
        pending: true,
        failed: false,
        all: false,
        end: "",
        start: "",
        page: 1,
      };
    } else if (key === "all") {
      newData = {
        ...transferData,
        success: false,
        pending: false,
        failed: false,
        all: true,
        end: "",
        start: "",
        page: 1,
      };
    } else if (key === "failed") {
      newData = {
        ...transferData,
        success: false,
        pending: false,
        failed: true,
        all: false,
        end: "",
        start: "",
        page: 1,
      };
    } else if (key === "start") {
      newData = { ...transferData, start: val };
    } else {
      newData = { ...transferData, [key]: val, page: 1 };
    }
    dispatch(updateTransferData(newData));
  };

  return (
    <>
      {/* dates */}
      <WebbDividerSmall />
      <div className="back-color-wite p-2 px-3 rounded-xd">
        <div className="mb-3">
          <label className="form-label text-small">
            Start Date <FormNeeded />
          </label>
          <input
            type="date"
            className="form-control height-md rounded-wd"
            style={{ fontSize: "0.9rem", height: "2.7rem" }}
            value={transferData?.start}
            onChange={({ target }) =>
              handleCheckboxChange("start", target.value)
            }
            disabled={loader}
          ></input>
        </div>

        <div className="mb-3">
          <label className="form-label text-small">
            End Date <FormNeeded />
          </label>
          <input
            type="date"
            className="form-control height-md rounded-wd"
            style={{ fontSize: "0.9rem", height: "2.7rem" }}
            value={transferData?.end}
            onChange={({ target }) => handleCheckboxChange("end", target.value)}
            disabled={loader}
          ></input>
        </div>
      </div>
    </>
  );
}
