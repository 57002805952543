// content
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { GetUserForm, NumberFormat } from "../../services/srvc-utilities";

import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";

import { AssetsDetails, UnitsCreate } from "../../services/srvc-assets-realm";
import { AssetsTransferCreate } from "../../services/srvc-transfers-assets-realm";
import { TokensDetails } from "../../services/srvc-tokens-realm";
import { AssetsCreatorsOffersList, AssetsOffersCreate } from "../../services/srvc-offers-realm";

import media from '../../data.media/contrato-card.png'


export default function AssetsOwnedDetailsUserModule() {


  const {id} = useParams()

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  
  const [submit, setSubmit] = useState(false);
  
  const [data, setData] = useState();
  const [balance, setBalance] = useState({number:'', ticker:'STX'})

  const [showModal, setShowModal] = useState(false);

  const [offers, setOffers] = useState([])

  const [refresh, setRefesh] = useState(false)

  const [sale, setSale] = useState('')
  const [rate, setRate] = useState('')
  const [user, setUser] = useState('')

  const [memo, setMemo] = useState('')

  const [unit, setUnit] = useState({status: {sale:false}})

  useEffect(() => {
    setLoader(true)
    if (asset) {
      const fetchData = async () => {
       
        var res = await  TokensDetails({ data: { item: id }, srvc: "" });
        if (res.stat) {
          setData(res.data);
          var bal = res.data.balance.mint - res.data.balance.sale - res.data.balance.burn
          setBalance({number:bal, ticker:'STX'})

          var resu = await AssetsCreatorsOffersList({data:{user:asset.item, unit: res.data.unit.item }})
          // console.log(resu)
          if(resu.stat) setOffers(resu.data.list)
        }
        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [refresh]);

  
  const handleSubmit = async () => {
    setMemo('Please Wait ...')
    setSubmit(true);
    setShowModal(true); // Show the modal
    var datax = {
      user: { mail:user },
      unit: {item:data.unit.item},
      sale: {number: sale, ticker: "STX"},
      rate: { number: rate, ticker: "INR" },
      debit: {"name": asset.name, "mail":asset.mail, "item":asset.item},
    };

    var res = await AssetsOffersCreate({ data: datax, srvc: "" });
    // console.log(res)
    if(res.stat){
        setMemo("Offer Created successfully.")
        setTimeout(()=>{
            window.location.reload(true)
        },1000)
    }
    else {
        setMemo(res.memo)
    }

  };

  const ResetValues = async()=>{
    setRate('')
    setSale('')
    setUser('')
    setMemo('')

  }


  if (loader) { return ( <div className="mx-3"> Please Wait... </div> ); }

  return (

    <>
    <div className="mx-3">
      
      <div className="p-3 back-color-wite rounded-xd">
        <p className="text-lead text-bold m-0">{data?.unit?.name || '******'}</p>
        <p className="text-normal m-0">{data?.unit?.number || '******'}</p>

        <WebbDividerMedium />
        <WebbDividerMedium />
        <p className="m-0">Balance</p>
        <p className="m-0">
          <span className="text-header-sm text-bold">{NumberFormat(balance.number, "w")}</span>
          <span className="ms-1">{balance.ticker}</span>
        </p>  

        {/* transfer */}
        <div className="d-none">
          <WebbDividerMedium />
          <button 
            className="btn btn-primary text-small rounded-xx" 
            data-bs-toggle="modal" data-bs-target="#exampleModal1" 
            disabled={balance.number==0}
          >Transfer</button>
        </div>


      </div>
    </div>

    {/* offers */}
    <WebbDividerSmall />
    <div className="mx-3">
      <div className="p-3 back-color-wite rounded-xd">
        <p className="text-normal text-bold m-0">Offers</p>

        <div className={offers.length <= 0 ? '' : 'd-none'}>
          No Active Offers
        </div>
      
        <div className={offers.length > 0 ? '' : 'd-none'}>
        <WebbDividerSmall />
        {offers.length>0 && offers.map((item,x)=> (
          <div className="" key={x}>
          <div className="d-flex mb-2 mt-2">
            <div className="">
              <p className="text-bold m-0">{item?.user?.name || '******'}</p>
              <p className="m-0">{item?.user?.mail || '******'}</p>
              <p className="m-0">{item?.sale?.nmbr || '*****'}{' units'}</p>

            </div>
            <div className="me-auto">

            </div>

          </div>        
          <div className="border-bottom"></div>
          </div>
        ))}
        </div>      
      
      </div>

    </div>

    {/* transfers */}
    <WebbDividerSmall />
    <div className="mx-3 d-none">
      <div className="p-3 back-color-wite rounded-xd">
        <p className="text-normal text-bold m-0">Transfers</p>
      </div>

      <div className={``}></div>

    </div>




    <div className="mx-3">




      {/* if unit.status.sale == false */}


      <div className={unit.status.sale ? "d-flex mt-3" :'d-none' }>
        <div className="me-auto d-none">
            <button className="btn btn-primary text-small rounded-xx" disabled>Burn</button>
        </div>
        <div className="text-end">
            <button className="btn btn-primary  rounded-xx" data-bs-toggle="modal" data-bs-target="#exampleModal1" disabled={balance.number==0}>Transfer</button>
        </div>

      </div>


      {/* modal */}
      <div
        className="modal fade rounded-xd"
        id="exampleModal1"
        tabIndex="-1"
        data-bs-backdrop="static" // Prevent closing when clicking outside the modal
        data-bs-keyboard="false" // Prevent closing with the Escape key
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog rounded-xd mt-5">
          <div className="modal-content w-100" >
            <div className="modal-header border-none align-middle">
              <p className="text-lead m-0" id="exampleModalLabel">
                Create Offer
              </p>
              <button
                onClick={()=> ResetValues()}
                type="button"
                className="btn-close "
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <label className="mb-1">Shares To Sell</label>
              <input min={0} max={9} type="number" value={sale} onChange={(e)=> setSale(e.target.value)} className="form-control mb-2" />
              <label className="mb-1">Rate</label>
              <input type="number"  value={rate} onChange={(e)=> setRate(e.target.value)} className="form-control mb-2" />
              <label className="mb-1">User (Email)</label>
              <input  value={user} onChange={(e)=> setUser(e.target.value)} className="form-control mb-2" />
            </div>

            <p className="text-small mx-3 text-primary mt -3">{memo}</p>

            <div className="modal-footer d-flex">
               <div className="me-auto"> <button onClick={()=>ResetValues()} type="button" className="btn btn-secondary rounded-xx text-small" data-bs-dismiss="modal">Close</button></div>
               <div className="text-end"> <button type="button" onClick={()=>handleSubmit()} className="btn btn-primary rounded-xx text-small" disabled={ !sale || !rate || !user || parseFloat(sale)> parseFloat(balance.number)}>Create</button></div>
            </div>

          </div>
        </div>
      </div>
    </div>

    </>
  );
}
