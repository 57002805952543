import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { UserContractDocumentsList } from "../../services/srvc-contract-realm";
import WebbSpinText from "../webx/webb-spintext";
import DocumentsListCard from "../webx/docx-listcard";


export default function DocumentsListModule() {

  const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();

  const [loader,setLoader]=useState(true);
  const [data,setData]=useState([]);

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoader(true);
        var res = await UserContractDocumentsList({data:{user:asset.item, index:"1", items:'100', filter:{}}, srvc:''})
        // console.log(res)
        if(res.data) setData(res.data.list)
       
        setLoader(false);
      }
      fetchData()
    } else {}
  },[asset.item]);

  // console.log(data)
  
  if (loader){ return ( <> <WebbSpinText /> </> ) }
  if (data.length===0) {return (<> No Records Found </>)}

  return (
  <>
    <div className="">
    {data && data.length > 0 && data.map((item, i) => (
      <Link to={`/${asset.role}/documents/view/${item.item}`} className="" key={i}>
        <DocumentsListCard data={item} />
      </Link>
    ))}
    </div>
  </>
  )
}