import { useNavigate, useParams } from "react-router-dom";
import FormNeeded from "../webx/form-needed";
import { useState } from "react";
import { toBase64 } from "../../services/srvc-utilities";
import { TeamsBasicInfoAdd } from "../../services/srvc-team-onboard";


import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId:  process.env.REACT_APP_AWS_ACCESS_KEY,  
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: 'ap-south-1', // e.g., 'us-east-1'
});

const s3 = new AWS.S3();

const TeamCreateBasicInfo = () => {

  const navigate = useNavigate()
  const {id} = useParams()
  const [submit, setSubmit] = useState(false)
  const [uploadStatus, setUploadStatus] = useState(false)
  const [memo, setMemo] = useState('')
  const [values, setValues] = useState({
    companyType: '',
    cinn:'',
    registrationNumber: '',
    incorporationDate: '',
    industryType: '',
    companylogo:''
  })

  const handleChange = (input) => (e) => {
    setValues({ ...values, [input]: e.target.value });
  };

  const handleFileChange = (input) => async(e) => {
    handleUpload(input, e.target.files[0] )
    
  };

  const handleUpload = async (input,file) => {
    if (!file) {
      alert('Please choose a file first!');
      return;
    }

    setUploadStatus(true)
    setMemo('Uploading File please wait ...')
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME, // Replace with your bucket name
      Key: `${id}/${Date.now()}`, // File name you want to save as in S3
      Body: file,
      ACL: 'public-read', // Make the file publicly accessible (optional)
      ContentType: file.type,
    };

    // Upload the file to S3
    // return await s3.upload(params)
    var result 
    await s3.upload(params, (err, data) => {
      if (err) {
        // console.error('Error uploading file:', err);
        alert(`${file?.name} could not uploaded, please choose other file or try again`)
        setUploadStatus(false);
        setMemo('File Upload Failed.')
      } else {
        // console.log('File uploaded successfully:', data);
        //console.log(`File uploaded successfully. File URL: ${data.Location}`);
        setValues({ ...values, [input]: data.Location });
        setUploadStatus(false)
        setMemo('File Saved Successfully.')
      }
    });
   
  };
    const continueStep = async(e) => { 
    e.preventDefault();
    setSubmit(true)
    setMemo('Please Wait...')
    var datx={type:'basic',team: id, values}
    var res = await TeamsBasicInfoAdd({data:datx, srvc:''})
    //console.log(res)
    if(res.stat) navigate(`/user/team/onboard/contact/${id}`)
    setMemo(res.memo)
    setSubmit(false)
      // nextStep ();
    };
  
    return (
      <div className="bg-white p-3  border rounded-xd">
        {/* <h6> Basic Information</h6> */}
        <form className="mt-3 mb-3">
          
          <label className="mb-1">Company Type:</label>
          <input type="text" onChange={handleChange('companyType')} value={values.companyType} className="form-control mb-3" placeholder="Private" />
  
          <label className="mb-1">Registration Number:</label>
          <input type="text" onChange={handleChange('registrationNumber')} value={values.registrationNumber} className="form-control mb-3" placeholder="U741XXX" />
  
          <label className="mb-1">CIN Number:</label>
          <input type="text" onChange={handleChange('cinn')} value={values.cinn} className="form-control mb-3" placeholder="CIN88XXXXX" />
  

          <label className="mb-1">Incorporation Date:</label>
          <input type="date" onChange={handleChange('incorporationDate')} value={values.incorporationDate} className="form-control mb-3"  />
  
          <label className="mb-1">Industry Type:</label>
          <input type="text" onChange={handleChange('industryType')} value={values.industryType} className="form-control mb-3" placeholder="Land & Agriculture" />
  
          <label className="mb-1">Company Logo:</label>
          <input type="file" onChange={handleFileChange('companylogo')} className="form-control" />
  
          <p className="text-small text-primary mt-2">{memo}</p>

          <button className="btn btn-primary rounded-xx mt-5 float-end" disabled={uploadStatus || submit } onClick={continueStep}>Next</button>
        </form>
      </div>
    );
  };
  
  export default TeamCreateBasicInfo;
  