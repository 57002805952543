import axios from "axios";
import services from '../data.services/data-services-main.json'
import { decrypt, encrypt } from "./srvc-encr-node";

// const base = (services.data.find(x => x.code === 'contracts')).link
const base =
  "https://ap-southeast-1.aws.data.mongodb-api.com/app/contrato-srvc-kcxltrc/endpoint";

// -----------------

export const ContractCreate = async (item) => {
  const basx = base + "/contract/create";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};

export const ContractList = async (item) => {
  const basx = base + "/contract/list/users";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};

export const ContractDetails = async (item) => {
  const basx = base + "/contract/details";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};

  export const ContractDocumentList = async (item) => {
    const basx = base + "/contract/documents/list";
    const head = {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
    };
    const datx = {
      data: item.data,
      srvc: process.env.REACT_APP_WEBB_SITE_SRVC,
    };

    const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

    var result;
    try {
      result = await axios.post(basx, { payload }, { headers: head });
      const data = await decrypt(
        result.data.data,
        process.env.REACT_APP_WEBB_SITE_SCRT
      );
      return { code: result.status, stat: result.data.stat, data };
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error.response.data };
    }
  };

  export const CreateContractDocument = async (item) => {
    const basx = base + "/document/create";
    const head = {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
    };
    const datx = {
      data: item.data,
      srvc: process.env.REACT_APP_WEBB_SITE_SRVC,
    };

    const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

    var result;
    try {
      result = await axios.post(basx, { payload }, { headers: head });
      const data = await decrypt(
        result.data.data,
        process.env.REACT_APP_WEBB_SITE_SCRT
      );
      return { code: result.status, stat: result.data.stat, data };
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error.response.data };
    }
  };

  export const DocumentFileAdd = async (item) => {
    const basx = base + "/document/add/file";
    const head = {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
    };
    const datx = {
      data: item.data,
      srvc: process.env.REACT_APP_WEBB_SITE_SRVC,
    };

    const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

    var result;
    try {
      result = await axios.post(basx, { payload }, { headers: head });
      const data = await decrypt(
        result.data.data,
        process.env.REACT_APP_WEBB_SITE_SCRT
      );
      return { code: result.status, stat: result.data.stat, data };
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error.response.data };
    }
  };


  export const ContractMembersSet = async (item) => {
    const basx = base + "/contract/members/set";
    const head = {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
    };
    const datx = {
      data: item.data,
      srvc: process.env.REACT_APP_WEBB_SITE_SRVC,
    };

    const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

    var result;
    try {
      result = await axios.post(basx, { payload }, { headers: head });
      const data = await decrypt(
        result.data.data,
        process.env.REACT_APP_WEBB_SITE_SCRT
      );
      return { code: result.status, stat: result.data.stat, data };
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error.response.data };
    }
  };

  export const ContractDocumentDetails = async (item) => {
    const basx = base + "/contract/document/details";
    const head = {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
    };
    const datx = {
      data: item.data,
      srvc: process.env.REACT_APP_WEBB_SITE_SRVC,
    };

    const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

    var result;
    try {
      result = await axios.post(basx, { payload }, { headers: head });
      const data = await decrypt(
        result.data.data,
        process.env.REACT_APP_WEBB_SITE_SCRT
      );
      return { code: result.status, stat: result.data.stat, data };
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error.response.data };
    }
  };

  export const ContractSendForEsignature = async (item) => {
    const basx = base + "/contract/send/esign";
    const head = {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
    };
    const datx = {
      data: item.data,
      srvc: process.env.REACT_APP_WEBB_SITE_SRVC,
    };

    const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

    var result;
    try {
      result = await axios.post(basx, { payload }, { headers: head });
      const data = await decrypt(
        result.data.data,
        process.env.REACT_APP_WEBB_SITE_SCRT
      );
      return { code: result.status, stat: result.data.stat, data };
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error.response.data };
    }
  };

  export const UserContractDocumentsList = async (item) => {
    const basx = base + "/documents/list/user";
    const head = {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
    };
    const datx = {
      data: item.data,
      srvc: process.env.REACT_APP_WEBB_SITE_SRVC,
    };

    const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

    var result;
    try {
      result = await axios.post(basx, { payload }, { headers: head });
      const data = await decrypt(
        result.data.data,
        process.env.REACT_APP_WEBB_SITE_SCRT
      );
      return { code: result.status, stat: result.data.stat, data };
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error.response.data };
    }
  };



  