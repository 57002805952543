import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Draggable from "react-draggable";

const FloatButton = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const ActionComponent = props.action;

  return (
    <>
      {/* <Draggable> */}
        <button
          variant="primary"
          onClick={handleShow}
          className="d-flex justify-content-center align-items-center border-0 d-md-none"
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            fontSize: "24px",
            backgroundColor: "green",
            color: "white",
            zIndex: "10",
          }}
        >
          +
        </button>
      {/* </Draggable> */}
      <Modal className="ps-5" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Actions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ActionComponent />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FloatButton;
