import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toBase64 } from "../../services/srvc-utilities";
import { TeamsBasicInfoAdd } from "../../services/srvc-team-onboard";

import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId:  process.env.REACT_APP_AWS_ACCESS_KEY,  
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: 'ap-south-1', // e.g., 'us-east-1'
});

const s3 = new AWS.S3();

const TeamCreateKeyPersonnel = () => {
  const navigate = useNavigate()
  const {id} = useParams()

  const [submit, setSubmit] = useState(false)
  const [memo, setMemo] = useState('')
  const [file, setfile] = useState()
  const [uploadStatus, setUploadStatus] = useState(false)
  const [step, setStep] = useState(1)

  const [values, setValues] = useState({
    d1Name: '',
    d1Email: '',
    d1Signature: '',

    d2Name: '',
    d2Email: '',
    d2Signature: '',

    s1Name: '',
    s1Email:'',
    s1Signature:''
  })

  const handleChange = (input) => (e) => {
    
    setValues({ ...values, [input]: e.target.value });
  };

  const handleFileChange = (input) => async(e) => {
    handleUpload(input, e.target.files[0] )
    // var base64 = await  toBase64(e.target.files[0])
    // setValues({ ...values, [input]: base64 });
    
  };


  const handleUpload = async (input,file) => {
    if (!file) {
      alert('Please choose a file first!');
      return;
    }

    setUploadStatus(true)
    setMemo('Uploading File please wait ...')
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME, // Replace with your bucket name
      Key: `${id}/${Date.now()}`, // File name you want to save as in S3
      Body: file,
      ACL: 'public-read', // Make the file publicly accessible (optional)
      ContentType: file.type,
    };

    // Upload the file to S3
    // return await s3.upload(params)
    var result 
    await s3.upload(params, (err, data) => {
      if (err) {
        // console.error('Error uploading file:', err);
        alert(`${file?.name} could not uploaded, please choose other file or try again`)
        setUploadStatus(false);
        setMemo('File Upload Failed.')
      } else {
        // console.log('File uploaded successfully:', data);
        //console.log(`File uploaded successfully. File URL: ${data.Location}`);
        setValues({ ...values, [input]: data.Location });
        setUploadStatus(false)
        setMemo('File Saved Successfully.')
      }
    });
   
  };

  const continueStep =async (e) => {
 
    e.preventDefault();
    setSubmit(true)
    setMemo('Please Wait...')
    var arr =[
      {name:values.d1Name, mail: values.d1Email, sign: values.d1Signature, role:'director1'},
      {name:values.d2Name, mail: values.d2Email, sign: values.d2Signature, role:'director2'},
      {name:values.s1Name, mail: values.s1Email, sign: values.s1Signature, role:'secretary1'}
    ]
    var datx={type:'personnel',team: id, values:arr}
    // console.log(datx)
    var res = await TeamsBasicInfoAdd({data:datx, srvc:''})
    // console.log(res)
    if(res.stat)  navigate(`/user/w/teams`)
    setMemo(res.memo)
    setSubmit(false)
   
  };
  
  
    return (
      <div className="bg-white p-3 border rounded-xd">
        {/* <h2>Step 3: Key Personnel</h2> */}
        <div className="mt-3">
          <div className={step==1 ? '' : 'd-none'}>
            <label className="mb-1">Director 1 Name</label>
            <input 
              type="text" 
              onChange={handleChange('d1Name')} 
              value={values.d1Name}
              className="form-control mb-3" 
            />
    
            <label className="mb-1">Director 1 Email:</label>
            <input 
              type="text" 

              onChange={handleChange('d1Email')} 
              value={values.d1Email} 
              className="form-control mb-3"
            />
    
            <label className="mb-1">Director 1 Signature (PNG Image) : </label>
            <input 
              accept="image/png"
              type="file"
              onChange={handleFileChange('d1Signature')} 
              // value={values.additionalContacts} 
              className="form-control mb-3"
            />

          <p className="text-small text-primary mt-2">{memo}</p>
          <button className="btn btn-primary rounded-xx mt-5 float-end" disabled={uploadStatus}  onClick={()=> {setStep(step=> step+1);setMemo('')}}>Next</button>

        </div>
        <div className={step== 2? '':'d-none'}>
          <label className="mb-1">Director 2 Name</label>
          <input 
            
            type="text" 
            onChange={handleChange('d2Name')} 
            value={values.d2Name}
            className="form-control mb-3" 
          />
  
          <label className="mb-1">Director 2 Email:</label>
          <input 
            type="text" 
            onChange={handleChange('d2Email')} 
            value={values.d2Email} 
            className="form-control mb-3"
          />
  
          <label className="mb-1">Director 2 Signature (PNG Image) : </label>
          <input 
            type="file"
            accept="image/png"
            onChange={handleFileChange('d2Signature')} 
            // value={values.additionalContacts} 
            className="form-control mb-3"
          />
          <p className="text-small text-primary mt-2">{memo}</p>
          <button className="btn btn-primary rounded-xx mt-5 float-end" disabled={uploadStatus}  onClick={()=> { setStep(step=> step+1); setMemo('') }}>Next</button>

        </div>

        <div className={step== 3? '':'d-none'}>
          <label className="mb-1">Secretary/any other authorized person </label>
          <input 
            type="text" 
            onChange={handleChange('s1Name')} 
            value={values.s1Name}
            className="form-control mb-3" 
          />
  
          <label className="mb-1">Secretary Email:</label>
          <input 
            type="text" 
            onChange={handleChange('s1Email')} 
            value={values.s1Email} 
            className="form-control mb-3"
          />
  
          <label className="mb-1">Secretary Signature (PNG Image) : </label>
          <input 
            type="file"
            accept="image/png"
            onChange={handleFileChange('s1Signature')} 
            // value={values.additionalContacts} 
            className="form-control mb-3"
          />

          <p className="text-small text-primary mt-2">{memo}</p>
          <button className="btn btn-primary rounded-xx mt-5 float-end" disabled={ uploadStatus||submit} onClick={continueStep}>Submit</button>

        </div>
  
       

        </div>
      </div>
    );
  };
  
  export default TeamCreateKeyPersonnel;
  