//date-time
export default function WebbDateTime() {
 
  return (
  <>
    <div className="">
      <span className="small text-tone">Today: {((new Date().toISOString().substr(0,10)))}</span>
    </div>
  </>
  )
}