// to be removed

// firebase file upload
import { useState, useRef } from "react";
import firebase from "./firebase";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import WebbProgressBar from "../content/webx/webb-progressbar";

const storage = getStorage();

export default function FileSaveFirebase (props) {

  const [stat,setStatus] = useState('Select File...');
  const [done,setDone] = useState(0);
  const [link,setLink] = useState('');

  const [file, setFile] = useState()
  let inputFile = useRef(null);

  const handleFile = e => {
    const { files } = e.target;
    if (files && files.length) {
      // const filename = files[0].name;
      // console.log("name", filename); //ex: zip, rar, jpg, svg etc.

      setFile(files[0]);
      props.file(files[0]);
      //handleUpload(files[0]);
    }
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const handleFileRemove = async (e) => {
    setFile(null)
    inputFile.current.value=null
    props.link('');
    setDone(0)
    setLink('')
    setStatus('Select File...')
  }

  const handleUpload = async (docx) => {

    setStatus('Please Wait... ')

    if (docx) {
      // console.log (docx)

      const filename = props.name.split('-').join('').toUpperCase() 
      + '.' + docx.name.split('.')[(docx.name.split('.')).length-1]
      
      const storageRef = ref(storage, 'contrato'+'/'+filename);
      const uploadTask = uploadBytesResumable(storageRef, docx);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on('state_changed',
        (snapshot) => {
          // status
          setDone (Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100));
          setStatus('Upload: ' + Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100) + '% done');

        }, 
        (error) => {
          switch (error.code) {
            case 'storage/unauthorized':
              break;
            case 'storage/canceled':
              break;
            case 'storage/unknown':
              break;
          }
        }, 
        () => {
            // download link
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // console.log('link:', downloadURL);
            setLink(downloadURL)
            // send url back
            props.link(downloadURL);
          });
          // document.getElementById("file").value = null
        }
      );

    }
 
  }

  return (
  <>

    <div className="d-flex p-3 rounded-none border back-color-wite mb-3">
      <div className="me-auto">
        <p className="fw-bold m-0 p-0">
          {file? file.name : 'Choose File'}
        </p>
        <p className="small m-0 p-0">
          {file ? Math.floor(file.size/1024)+' kb' : '---'}
        </p>
        
      </div>

      <div className={done==0 ? 'text-end': 'd-none'} style={{width:'15%'}}>
        <button className="btn rounded-circle back-color-lite m-0 p-0  hilite"
          style={{width:'2.4rem', height:'2.4rem'}}
          onClick={() => onButtonClick()}  
        ><i className="bi-plus"></i>
        </button>
      </div>

      <div className={done>0 && done <100 ? 'text-end': 'd-none'} style={{width:'15%'}}>
        <button className="btn rounded-circle back-color-lite m-0 p-0  hilite"
          style={{width:'2.4rem', height:'2.4rem'}}
          disabled 
        ><i className="bi-arrow-up"></i>
        </button>
      </div>

      <div className={done==100 ? 'text-end': 'd-none'} style={{width:'15%'}}>
        <button className="btn rounded-circle back-color-lite m-0 p-0  hilite"
          style={{width:'2.4rem', height:'2.4rem'}}
          onClick={() => handleFileRemove()}  
        ><i className="bi-x"></i>
        </button>
      </div>
    </div>

    <div className="mb-3 d-none">
      <WebbProgressBar stat={done}/>
      <div className="mb-2"></div>
      <p className="small">{'Status: '}{stat}</p>
    </div>

    <div className="mb-3">
      <input 
        type="file" accept="application/pdf" id="file"
        ref={inputFile}
        onChange= {handleFile} 
        style={{display: "none"}}
      ></input>
    </div>

  </>
  )
}