// user account
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { NumberFormat, CardNumberFormat } from "../../services/srvc-utilities";

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";


import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import { UserDocumentList } from "../../services/srvc-media-docs-realm";

const list = require('../../data.static/data-documents-user.json').data.filter(item=>item.actv)

export default function UserDocumentListModule () {

  
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  // console.log(asset)
  
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);

  const [data, setData] = useState([])

  useEffect( () => {

    if (asset){
      const fetchData = async() => {
        setLoader(true);
        const result = await UserDocumentList({data: {user: asset.item} })
        // console.log (result) 
        setData(list)

        // if (result.stat) {
        
        //   setData(result.data.list)
        // }


        setLoader(false);
      }
      fetchData()
    } else {}
  },[]);

  
  if (loader) return <>Please Wait...</>


  return (
  <>
    {/* info */}

    {/* data */}
    <div className="rounded-wd back-color-wite border">
      {data && data.length > 0 && data.map((item, i) => (
  
        <div className="p-1 border-bottom border-light">
  
          <div className={`d-flex cursor p-2 hilite rounded-wd ${ i < data.length-1 ? '': ''}`} key={i}
            // onClick={()=>{handleChange('item',item.name); handleCallback(item.name)}}
            // onClick={()=>{setSort({'item':item.name}); navigate(`/${usxx}/${mode.item}/${item.link}`)}}
            
          >
          
            <div className="">
              <span className="text-small">
               
              </span>
            </div>
            
            <div className="">
              <p className={`text-bold m-0 p-0`}>{item.meta.name}</p>
              <p className={`text-normal m-0 p-0`}>{item.meta.memo}</p>
            </div>
  
            <div className="ms-auto">
              <i className={`m-0 p-0 text-color-tint bi-chevron-right`}></i>
            </div>
          
          </div>
          <div className="border-bottom border-light d-none"></div>
        </div>
      ))}
      </div>

  </>

  )
}