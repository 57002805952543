// module
import { useEffect, useState } from "react";
import {  useNavigate, useParams } from 'react-router-dom';


import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { GetUserForm } from '../../services/srvc-utilities'

import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";

const list = (require("../../data.static/data-select-vault.json")).data.filter(item => item.actv);

export default function AssetsRevokeModule() {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [form, setForm] = useState(false);
  const [submit, setSubmit] = useState(false);

  const [memo, setMemo] = useState('');
  const [data, setData] = useState({    
    user: '',
    number: ''
  })

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        
        // // basic info
        // var res = (await GetDocumentInfo(JSON.stringify({
        //   data: { document: id },
        //   user: asset.user,
        //   webb: process.env.REACT_APP_WEBB_SITE_CLNT
        // }))).data

        // // console.log(res)
        // setInfo( res );

        setLoader(false);
      }
      fetchData()
    } else {}
  },[asset.usid]);



  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async () => {
  
  }


//if (loader){ return ( <> <WebbLoaderSmall /> </> ) }

return (
  <div className="">
    
    {/* info */}
    <p className="m-0">Please enter User Details, Number of Units and Transfer Rate</p>
    <WebbDividerMedium />

    {/* data */}
    <div className="">

      <div className="form-group mb-3">
        <label className="form-label small">Select User <FormNeeded/></label>
        <select 
          className="form-select rounded-none"
          style={{fontSize:'0.9rem', height:'2.7rem'}}
          value={data.form}
          onChange={({ target }) => handleChange("user", target.value)}
        >
          <option value=''>Select User</option>
            {list.map((item,i) => (
              <option key={i} value={item.link}>{item.name}</option>
          ))}
        </select>
      </div>

      <div className="form-group mb-3 ">
        <label className="form-label small">Number of Units <FormNeeded/></label>
        <input type="text" className="form-control rounded-none"
          style={{fontSize:'0.9rem', height:'2.7rem'}}
          value={data.name}
          onChange={({ target }) => {handleChange("number", target.value);}}
          placeholder="0123">
        </input>
      </div>

    </div>


    <WebbDividerMedium />
    <div className="mx-3">
      <p className="m-0">{memo}</p>
    </div>

    <WebbDividerMedium />
    <div className={submit ? 'd-none' : 'mx-3'}>
      <WebbDividerMedium />
      <div className="d-flex justify-content-between">

        <button className={`btn btn-light border back-color-wite rounded-xx text-small`}
          type="button"
          disabled={loader || submit}
          onClick={()=> { navigate('/auth/next')}}
        >{loader ? 'Please Wait...' : 'Cancel'}</button>

        <button className={`btn btn-success border-none rounded-xx text-small`}
          type="button"
          disabled={!form || loader || submit}
          onClick={()=> { handleSubmit()}}
        >{loader ? 'Please Wait...' : 'Continue'}</button>

      </div>


    </div>
  
    <WebbDividerMedium />
    <WebbDividerMedium />
  </div>
  )
}