import Jazzicon from "react-jazzicon";
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xx";
import WebbHeader from "../content/webz/webb-header-xx";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";

import { useState } from "react";
import DocumentsNewFileModule from "../content/document/new-document-file";

const metadata = {
  name: "Create New Document",
  memo: process.env.NEXT_PUBLIC_WEBB_SITE_MEMO,
  words: process.env.NEXT_PUBLIC_WEBB_SITE_WORD,
  banner: {
    link: "https://img.freepik.com/free-vector/mountains-landscape-with-tropical-forest-night_23-2148268994.jpg?w=826",
  },
  media: {
    link: "https://firebasestorage.googleapis.com/v0/b/carbonizecc.appspot.com/o/terano-banner.png?alt=media&token=4a2aa0ca-74c5-40e8-a811-0e651edf002f",
  },
  created: "2020-03-16T05:35:07.322Z",
  modified: "2020-03-16T05:35:07.322Z",
  domain: "main",
};

export default function NewDocumentView() {
  const [showForm, setShowForm] = useState(true);


  const handleClick = () => {
    
  };

  return (
    <>
      
      <Helmet>
        <title>{metadata.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata?.name}
        header={{
          size: "small",
          show: true,
          data: (
            <>
              <div className="sticky-top">
                <WebbHeader data={{ home: "/", name: metadata.name, link:'home' }} />
              </div>
            </>
          ),
        }}
        media={{
          size: "xtra",
          show: false,
          link: metadata.banner.link,
          memo: metadata.memo,
          data: (
            <>
              <WebbDividerMedium />

              <div className="container text-color-wite">
                <h1 className="text-lead text-bold mx-3">
                  {metadata?.name}
                </h1>
              </div>

              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerSmall />
            </>
          ),
        }}
        content={{
          size: "small",
          show: true,
          data: (
            <>
              <WebbDividerMedium />
              <DocumentsNewFileModule />

              <WebbDividerSmall />
              
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          ),
        }}
        footer={{
          size: "xtra",
          show: true,
          data: (
            <>
              <div className="back-color-lite text-color-tone">
                <WebbDividerSmall />
                <div className="container">{/* <WebbFooterBase /> */}</div>
                <WebbDividerSmall />
                {/* <WebbFooterMobile /> */}
              </div>
            </>
          ),
        }}
      ></ContentFormat>
    </>
  );
}