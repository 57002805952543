// network
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

const list = require("../../data.static/data-select-user.json").data.filter(
  (item) => item.actv
);

export default function UserSelectMobileModule(props) {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState([]);
  const [mode, setMode] = useState({ item: location.pathname.split("/")[2] });
    const [sort, setSort] = useState({ item: location.pathname.split("/")[3] });
     const [search, setSearch] = useState("");

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        setData(list);
        // setData(list.filter(item => item.user.includes(asset.form)))
        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [asset.item]);

  const handleChange = async (key, val) => {
    setSort({ ...sort, [key]: val });
  };

  const handleSelect = async (sort) => {
    // console.log('item: ', sort);
    handleCallback(sort);
  };

  const handleCallback = (srtx) => {
    props.item(srtx);
  };

  // if (loader){ return ( <> </> ) }

  return (
    <>
      {/* <div className="rounded-wd back-color-wite d-flex gap-2 mb-4 flex-wrap d-md-none">
        {data &&
          data.length > 0 &&
          data.map((item, i) => (
            <div className="p-1 border-light border rounded-xd w-100 ">
              <div
                className={`d-flex cursor p-2 hilite rounded-wd ${
                  i < data.length - 1 ? "" : ""
                }`}
                key={i}
                // onClick={()=>{handleChange('item',item.name); handleCallback(item.name)}}
                onClick={() => {
                  setSort({ item: item.name });
                  navigate(`/${usxx}/${mode.item}/${item.link}`);
                }}
              >
                <div className="">
                  <span className="text-small">
                    <i
                      className={`m-0 p-0 small bx bxs-circle text-${
                        sort.item === item.link ? "color-next" : "color-lite"
                      }`}
                    ></i>
                  </span>
                </div>

                <div className="ms-2">
                  <p
                    className={`m-0 p-0 text-${
                      sort.item === item.link
                        ? "color-next text-bold"
                        : "color-tone"
                    }`}
                  >
                    {item.name}
                  </p>
                </div>

                <div className="ms-auto">
                  <i className={`m-0 p-0 text-color-tint bi-chevron-right`}></i>
                </div>
              </div>
              <div className="border-bottom border-light d-none"></div>
            </div>
          ))}
      </div> */}

      <div className="d-flex justify-content-between mb-4 d-md-none">
        <div className="mx-3">
          {data &&
            data.map((item, i) => (
              <span
                className={item.actv ? "" : "d-none"}
                key={i}
                onClick={() => {
                  setSort({ item: item.name });
                  navigate(`/${usxx}/${mode.item}/${item.link}`);
                }}
              >
                <span
                  className={`p-2 px-3 rounded-xx text-small ${
                    sort.item === item.link
                      ? "back-color-next text-color-wite"
                      : "back-color-wite cursor"
                  }`}
                >
                  {item.name}
                </span>
                <span className=" me-md-2 me-0"></span>
              </span>
            ))}
        </div>
        {/* <div
          className="d-flex align-items-center d-md-none gap-1 text-small cursor"
          onClick={openModal}
        >
          Filter
          <i className="bx bx-slider-alt me-2" style={{ fontSize: "14px" }}></i>
        </div> */}
      </div>
    </>
  );
}
