// module
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

const list = require("../../data.static/data-shares-actions.json").data;

export default function SharesUserActionsModule() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [actions, setActions] = useState();

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const result = list.filter((x) => x.user.includes(asset.role));
        setActions(result);

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

  const handleClick = (live, link) => {
    if (live) navigate(`/${asset.role}/${link}`);
  };

  if (loader) return <></>;

  return (
    <>
      <div className="">
        {actions &&
          actions.map((item, i) =>
            item.actv ? (
              <div
                className={`d-flex mb-2 px-2 align-middle align-items-center hilite ${
                  item.live ? "cursor hover rounded-wd" : "text-color-tint"
                }`}
                style={{ height: "2.4rem" }}
                key={i}
                onClick={() => handleClick(item.live, item.link)}
              >
                <i
                  className={`m-0 p-0 ${item.icon} `}
                  style={{ fontSize: "1.35rem" }}
                ></i>
                <span className="align-middle m-0 p-0 ms-2 text-nowrap">
                  {item.name}
                </span>
                <i
                  className={`m-0 p-0 ms-auto bx bx-chevron-right`}
                  style={{ fontSize: "1.35rem" }}
                ></i>
              </div>
            ) : (
              ""
            )
          )}
      </div>
    </>
  );
}
