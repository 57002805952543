import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toBase64 } from "../../services/srvc-utilities";
import { TeamsBasicInfoAdd } from "../../services/srvc-team-onboard";

const TeamCreateLegalFinancialInfo = () => {
    
  const navigate = useNavigate()
  const {id} = useParams()

  const [submit, setSubmit] = useState(false)
  const [memo, setMemo] = useState('')

  const [values, setValues] = useState({
    tinNumber: '',
    bankAccountInfo: '',
    authorizedSignatoriesName: '',
    authorizedSignatoriesSign:''
  })

  const handleChange = (input) => (e) => {
    setValues({ ...values, [input]: e.target.value });
  };
    const continueStep = async(e) => {
      
    e.preventDefault();
    setSubmit(true)
    setMemo('Please Wait...')
    var datx={type:'legal',team: id, values}
    var res = await TeamsBasicInfoAdd({data:datx, srvc:''})
    // console.log(res)
    if(res.stat) navigate(`/user/team/onboard/member/${id}`)
    setMemo(res.memo)
    setSubmit(false)
    };
  
    const handleFileChange = (input) => async(e) => {
      var base64 = await  toBase64(e.target.files[0])
      setValues({ ...values, [input]: base64 });
      
    };
  
    
  
    return (
      <div className="bg-white p-3 border rounded-xd">
        {/* <h6>Legal & Financial Information</h6> */}
        <form className="mt-3">
          <label className="mb-1">Tax Identification Number (TIN):</label>
          <input 
            type="text" 
            onChange={handleChange('tinNumber')} 
            value={values.tinNumber} 
            className="form-control mb-3"
          />
  
          <label className="mb-1">Bank Account Information (Optional):</label>
          <input 
            type="text" 
            onChange={handleChange('bankAccountInfo')} 
            value={values.bankAccountInfo} 
            className="form-control mb-3"
          />
  
          <label className="mb-1">Authorized Signatory Name:</label>
          <input 
            onChange={handleChange('authorizedSignatoriesName')} 
            value={values.authorizedSignatoriesName} 
            className="form-control mb-3"
          />

          <label className="mb-1">Authorized Signatory Sign (PNG Image):</label>
          <input 
            type="file"
            onChange={handleFileChange('authorizedSignatoriesSign')} 
            // value={values.authorizedSignatories} 
            className="form-control mb-3"
          />

        <p className="text-small text-primary mt-2">{memo}</p>
         
        <button className="btn btn-primary rounded-xx mt-5 float-end" disabled={submit} onClick={continueStep}>Next</button>
        </form>
      </div>
    );
  };
  
  export default TeamCreateLegalFinancialInfo;
  