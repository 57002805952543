// documents list

const code = require('../../data.static/code-contracts.json').data;

export default function DocumentsListCard(props) {

  const data = props.data;
  // console.log (data)
  const meta = props.data.meta;

  return (
  <>
      <div className="d-flex bg-wite p-2 px-3 border rounded-xd mb-2 hilite mt-4 bg-white">

        <div className="">
          <p className="m-0 p-0 text-color-main fw-bold">{meta.name}</p>
          <p className="m-0 p-0 small text-dark">{meta.memo}</p>
          <p className="m-0 p-0 small text-dark">
            <span>Created: {(new Date(data.crts)).toLocaleString().substring(0,10)}</span>
          </p>
        </div>
        
        {/* status */}
        <div className="ms-auto text-end py-1">
          
          <div className={`p-1 px-3 rounded-pill bg-${code.find(x=> x.code == (data && data.stat)).colr}`}>
            <p className={`small m-0 ${(data && data.stat) == '0' ? 'text-dark' : 'text-white'}`}>
              <small> {code.find(x=>parseInt(x.code) == (data && data.stat)).text.toUpperCase()}</small>
            </p>
          </div>

        </div>
        
      </div>

  </>
  )
}