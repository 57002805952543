// main
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import ContentFormat from '../content/webz/content-format-xv'
import WebbHeader from '../content/webz/webb-header-navs-xv'
import WebbIcon from '../content/webx/webb-icon'
import UserAvatar from '../content/webx/user-avatar'
import WebbDateTime from '../content/webx/webb-datetime'

import { GetLocalBusiness, GetLocalUser } from '../services/srvc-auth-local'
import { GetUserForm } from '../services/srvc-utilities'

import WebbDividerSmall from '../content/webx/webb-divider-sm'
import TeamInfocardHomeModule from '../content/user/team-infocard-home'
import AssetsListUserFundedModule from '../content/assets/assets-list-user-funded'
import WebbDividerMedium from '../content/webx/webb-divider-md'
import { ContractListPendingModule } from '../content/contracts/contracts-list-pending'
import { OffersListPendingModule } from "../content/offers/offers-list-pending";
import TransfersFundsListWaitModule from '../content/transfers.funds/transfers-funds-list-wait'
import WebbMenuMobile from '../content/webz/webb-menu-xv'
import MenuButtons from '../content/webx/menu-buttons'



export default function HomeUser () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  const [assetSearchData, setAssetSearchData] = useState({});
  

  const metadata = {
    name: 'Home',
    banner: { link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'}
  }
  
  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: "xtra", show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`/${asset.role}/home`}>
                <WebbIcon data={{ color: "text-color-main", size: "text-icon-md" }}/>
              </Link>
            </>
          ),
          data: (
            <>
              {" "}
              <WebbHeader />{" "}
            </>
          ),
          footer: (
            <>
              {" "}
              <UserAvatar />{" "}
            </>
          ),
        }}
        sidebar={{
          header: (
            <>
              <p className="text-normal text-bold m-0">{metadata.name}</p>
            </>
          ),
          data: (
            <>
              <TeamInfocardHomeModule />           
              <WebbDividerSmall />
            </>
          ),
          footer: (
            <>
              <WebbDateTime />
            </>
          ),
        }}
        content={{
          header: (
            <>
              <div className="d-flex justify-content-between justify-content-md-start align-items-start">
                <div className="d-flex gap-3">
                  <i
                    data-bs-toggle="offcanvas"
                    href="#offcanvasExample"
                    role="button"
                    aria-controls="offcanvasExample"
                    className="bx bx-menu d-block d-md-none"
                    style={{ fontSize: "25px" }}
                  ></i>
                  <div
                    className="offcanvas offcanvas-start w-75 bg-body-tertiary"
                    tabIndex="-1"
                    id="offcanvasExample"
                    aria-labelledby="offcanvasExampleLabel"
                  >
                    <div className="offcanvas-header">
                      <h6
                        className="offcanvas-title"
                        id="offcanvasExampleLabel"
                      >
                        Contrato
                      </h6>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div
                      className="offcanvas-body d-flex flex-column gap-1"
                      style={{ position: "relative" }}
                    >
                      <TeamInfocardHomeModule />                     
                      <WebbMenuMobile />
                      <div
                        style={{
                          position: "absolute",
                          bottom: "15px",
                          width: "88%",
                        }}
                      >
                        <MenuButtons />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="m-0">Dashboard</p>
                </div>
                <div
                  className="d-block d-md-none"
                  style={{ position: "relative", bottom: "6px" }}
                >
                  <UserAvatar />
                </div>
              </div>
            </>
          ),
          data: (
            <>
              <h2 className="text-normal m-0 mx-3">
                {"Equity Shares (Owned)"}
              </h2>
              <WebbDividerSmall />
              <AssetsListUserFundedModule assetSearchData={assetSearchData} />
              <WebbDividerMedium />
              <WebbDividerSmall />
              
              <h2 className="text-normal m-0 ms-3">
                {"Pending Document Signature"}
              </h2>
              <WebbDividerSmall />
              <ContractListPendingModule />
              {/* <WebbDividerMedium /> */}
              {/* <h2 className="text-normal m-0 ms-3">
                {"Pending Fund Transfers"}
              </h2>
              <WebbDividerSmall />
              <TransfersFundsListWaitModule /> */}
              {/* <WebbDividerMedium />
              <h2 className="text-normal m-0 ms-3">
                {"Pending Asset Transfers"}
              </h2>
              <WebbDividerSmall /> */}
              {/* <WebbDividerSmall /> */}
              <WebbDividerMedium />
              <WebbDividerSmall />
              <h2 className="text-normal m-0 ms-3">{"My Offers "}</h2>
              <WebbDividerSmall />
              <OffersListPendingModule />

              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          ),
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: <></>,
          footer: <></>,
        }}
      />
    </>
  );
}
