// documents
import { Link, useHistory, useNavigate, useParams } from "react-router-dom";

import WebbDividerMedium from "./webb-divider-md";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

export default function ContractFiles(props) {

  const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();
  const { id } = useParams();
  
  const data = props.data
  const status = props.status
  const smartid = props.smartid

  var base64=data.file

  const navigate = useNavigate()

  function DownloadDocx(data) {
    const linkSource = `data:application/pdf;base64,${data.file}`;
    const downloadLink = document.createElement("a");
    const fileName = data.nmbr;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const handleClick = (item) => {
    navigate(`/${asset.role}/contract/document/${item}`)
  }

  if (data.length==0) 
  return (
  <> 
    <p className="text-color-tone m-0">No Documents</p>
    <WebbDividerMedium/>
  </>
  )

  return (
  <>
    <div className={`rounded   back-color-wite mb-3 cursor `} >
    {data && data.length > 0 && data.map((item, i) => (
      <div key={i}>
      <div className="d-flex p-3 hilite border mb-1" onClick={()=>handleClick(item.item)}>
        <div className="me-auto">
          <p className="fw-bold m-0 p-0 text-sm">{item.meta.name}</p>
          <p className="small m-0 p-0 text-tone text-sm">{'ID: '}{item.item}</p>
          <p className="small m-0 p-0 text-tone text-sm">{'Created: '}{(new Date(item.crts)).toLocaleString()}</p>
        </div>

        <div className={`text-end py-3`}>
          <i className="bi bi-chevron-right text-color-tone"></i>
        </div>
      </div>
      
      <div className={`border-bottom border-light ${i < data.length-1 ? '' :'d-none'}`}></div>
      </div>
    ))}
    </div>

  </>
  )
}