// content
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoaderMedium from "../webx/webb-loader-md";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";


const list = require("../../data.static/data-vault-actions.json").data;


export default function VaultActionsUserModule () {

  
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [actions, setActions] = useState()

  // console.log(listactions.filter(x => x.user.includes(asset.role)))

  useEffect( () => {
    if (asset){

      const fetchData = async() => {
        setLoader(true);

        const result = list.filter(x => x.user.includes(asset.role))
        setActions(result)

        setLoader(false);
      }
      fetchData()
    } else {}
  },[]);

  const handleClick = (live, link) => {
    if (live) navigate(`/${asset.role}/${link}`)
  }

  if (loader) return <></>


  return (
    <>
      <div className="">
        {actions &&
          actions.map((item, i) =>
            item.actv ? (
              <div
                className={`d-flex px-2 mb-2 align-middle align-items-center 
            ${item.live ? "cursor hilite rounded-wd" : "text-color-tint"}`}
                style={{ height: "2.4rem" }}
                key={i}
                // data-bs-toggle='modal' data-bs-target={item.link}
                onClick={() => handleClick(item.live, item.link)}
              >
                {/* <div className=""> */}
                <i
                  className={`m-0 p-0 ${item.icon} `}
                  style={{ fontSize: "1.35rem" }}
                ></i>
                {/* </div> */}
                {/* <div className="ms-2"> */}
                {/* <p className={`m-0 p-0 text-nowrap`}> */}
                <span className="text-small align-middle p-0 text-nowrap ms-2">
                  {item.name}
                </span>
                {/* </p> */}
                {/* </div> */}
                {/* <div className="ms-auto "> */}
                  <i
                    className={`m-0 p-0 bx bx-chevron-right ms-auto`}
                    style={{ fontSize: "1.35rem" }}
                  ></i>
                {/* </div> */}
              </div>
            ) : (
              ""
            )
          )}
      </div>
    </>
  );
}