import React, { useCallback, useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import axios from "axios";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";

const NetworkNewMail = () => {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [recipients, setRecipients] = useState([]);
  const [subject, setSubject] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [pendingImages, setPendingImages] = useState([]);
  const [email, setEmail] = useState("");
  const [allMail, setAllMail] = useState("");
  const fileInputRef = useRef(null);

  const resetForm = () => {
    setEmail("");
    setRecipients([]);
    setSubject("");
    setEditorState(/* initial editor state */);
    setAttachments([]);
    fileInputRef.current.value = null;
  };

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const entityMap = rawContent.entityMap;
    const newPendingImages = [];
    Object.keys(entityMap).forEach((key) => {
      if (entityMap[key].type === "IMAGE" && entityMap[key].data.src) {
        newPendingImages.push(entityMap[key].data.src);
      }
    });
    setPendingImages(newPendingImages);
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleFileAdd = (newFile) => {
    const newFilesArray = Array.from(newFile);
    setAttachments((prevAttachments) => [...prevAttachments, ...newFilesArray]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleLogContent = async () => {
    // const uploadedImages = await Promise.all(
    //   pendingImages.map(async (src) => {
    //     const datx = {
    //       data: {
    //         type: "local",
    //         file: src,
    //         user: { user: "mruthunjay" },
    //         asset: "******",
    //       },
    //       srvc: "******",
    //     };
    //     const base = "url";
    //     const head = { Authorization: ["******"] };

    //     try {
    //       console.log("Sending request with data:", datx);
    //       const res = await axios.post(base, datx, { headers: head });
    //       console.log("Response:", res);
    //       return { originalSrc: src, newSrc: res.data.data.link || "" };
    //     } catch (error) {
    //       console.error(
    //         "Error uploader image:",
    //         error.response ? error.response.data : error.message
    //       );
    //       return { originalSrc: src, newSrc: src };
    //     }
    //   })
    // );

    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const entityMap = rawContent.entityMap;

    // Object.keys(entityMap).forEach((key) => {
    //   if (entityMap[key].type === "IMAGE") {
    //     const image = uploadedImages.find(
    //       (img) => img.originalSrc === entityMap[key].data.src
    //     );
    //     if (image) {
    //       entityMap[key].data.src = image.newSrc;
    //     }
    //   }
    // });

    const updatedRawContent = { ...rawContent, entityMap };
    const updatedContentState = convertFromRaw(updatedRawContent);
    const newEditorStateWithUpdates = EditorState.push(
      editorState,
      updatedContentState,
      "apply-entity"
    );
    setEditorState(newEditorStateWithUpdates);

    const htmlString = draftToHtml(
      convertToRaw(newEditorStateWithUpdates.getCurrentContent())
    );

    const fileBase64Array = await Promise.all(
      attachments.map((file) =>
        fileToBase64(file).then((base64) => ({
          name: file.name,
          base64,
        }))
      )
    );

    const dataX = {
      data: {
        recipient: recipients,
        subject,
        content: htmlString,
        file: fileBase64Array,
      },
    };
    // console.log(dataX);
    // const url = "url";
    // const result = await axios.post(url, dataX);
    // console.log(result.data);
    resetForm();
  };

  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve({ data: { link: reader.result } });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const toolbarOptions = {
    options: [
      "inline",
      "fontSize",
      "list",
      "textAlign",
      "emoji",
      "link",
      "image",
      "history",
    ],
    inline: {
      options: ["bold", "italic", "underline", "strikethrough", "monospace"],
    },
    blockType: {
      inDropdown: true,
    },
    fontSize: {
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36],
    },
    list: {
      inDropdown: false,
      options: ["unordered", "ordered", "indent", "outdent"],
    },
    textAlign: {
      inDropdown: false,
      options: ["left", "center", "right", "justify"],
    },
    emoji: {},
    link: {
      defaultTargetOption: "_blank",
    },
    image: {
      uploadCallback: uploadImageCallBack,
      previewImage: true,
      alignmentEnabled: true,
      alt: { present: true, mandatory: false },
      defaultSize: {
        height: "auto",
        width: "auto",
      },
    },
    history: {
      inDropdown: false,
      options: ["undo", "redo"],
    },
  };

  const handleAddRecipient = (e) => {
    if (e.key === "Enter" && email.trim()) {
      setRecipients([...recipients, email.trim()]);
      setEmail("");
    }
  };
  // const handleAddAllRecipients = useCallback(() => {
  //   setRecipients(allMail);
  // }, [allMail]);

  return (
    <div className="">
      <div className="d-flex justify-content-center align-items-center">
        <input
          type="email"
          style={{ flex: "1" }}
          className="form-control mb-2"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={handleAddRecipient}
          placeholder="Add recipients:"
        />
        {/* <p
          onClick={handleAddAllRecipients}
          className="btn btn-success mb-2 p-1 ms-1"
        >
          Add All
        </p> */}
        <p
          onClick={() => setRecipients([])}
          className="btn btn-success mb-2 p-1 ms-1"
          style={{ whiteSpace: 'nowrap' }}
        >
          Remove All
        </p>
      </div>
      <div
        className="d-flex"
        style={{ overflowY: "auto", scrollbarWidth: "thin" }}
      >
        {Array.isArray(recipients) &&
          recipients.map((recipient, index) => (
            <div
              key={index}
              className="d-flex align-center justify-content-between mx-1"
            >
              <p className="border border-primary rounded p-1">
                {" "}
                {recipient}
                <span
                  className="ms-4 p-1"
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={() =>
                    setRecipients(recipients.filter((_, i) => i !== index))
                  }
                >
                  x
                </span>
              </p>
            </div>
          ))}
      </div>
      <input
        placeholder="Subject:"
        className="form-control mb-2"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      <Editor
        editorStyle={{
          height: "400px",
          overflowY: "auto",
          backgroundColor: "white",
        }}
        editorState={editorState}
        toolbarClassName="border border-dark-subtle d-flex justify-content-center m-0"
        editorClassName="border border-secondary p-2 editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={toolbarOptions}
        spellCheck={true}
        autoCapitalize="on"
        stripPastedStyles={false}
      />
      <div className="d-flex justify-content-between mt-3 ">
        <div>
          <button
            className="btn btn-outline-primary"
            type="button"
            onClick={handleButtonClick}
            style={{ whiteSpace: "nowrap", width: "auto" }}
          >
            {" "}
            Choose Attachment file
          </button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            multiple
            onChange={(e) => handleFileAdd(e.target.files)}
          />
          <div className="mt-2">
            {attachments.map((item, i) => (
              <p key={i} className="d-flex justify-content-between">
                {item.name}
                <span
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={() =>
                    setAttachments(
                      attachments.filter((_, index) => index !== i)
                    )
                  }
                >
                  X
                </span>
              </p>
            ))}
          </div>
        </div>
        <button onClick={handleLogContent} className="btn btn-success">
          Send Email
        </button>
      </div>
    </div>
  );
};

export default NetworkNewMail;
