// user account
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { NumberFormat, CardNumberFormat } from "../../services/srvc-utilities";

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbIcon from "../webx/webb-icon";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

// import { AccountsBaseBalance } from "../../services/srvc-accounts-realm";

// const media = 'https://img.freepik.com/free-vector/abstract-glowing-particles-background-blue-red-color_1017-26575.jpg?w=900'
const media = 'https://img.freepik.com/free-photo/blockchain-technology-background-gradient-blue_53876-124646.jpg?w=300'

export default function UserInfocardModule () {

  
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  // console.log(asset)
  
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);

  const [balance, setBalance] = useState({number: '000000', ticker: '******'})
  const [data, setData] = useState()

  useEffect( () => {

    if (asset){
      const fetchData = async() => {
        setLoader(true);
        // const result = await AccountsBaseBalance({data: {user: asset.item},service: asset?.creds?.service,client:asset?.creds?.client,secret:asset?.creds?.secret})
        // console.log (result) 

        // if (result.stat) {
        //   setBalance(result.data.balance)
        //   setData(result.data)
        // }


        setLoader(false);
      }
      fetchData()
    } else {}
  },[]);

  // if (loader) return <WebbLoaderSmall/>


  return (
  <>
    {/* info */}

    {/* data */}
    <div className="rounded-xd border rounded-xd p-3">
      
      <div className="">
        <p className="text-small mb-0">Name</p>
        <p className="text-normal text-sm m-0">{asset?.name || '******'}</p>
        <WebbDividerSmall />

        <p className="text-small mb-0">Email Address</p>
        <p className="text-normal text-sm m-0">{asset?.mail || '******'}</p>
        <WebbDividerSmall />

        <p className="text-small mb-0">Mobile Number</p>
        <p className="text-normal text-sm m-0">{asset?.mobile || '******'}</p>      
        <WebbDividerSmall />

        <p className="text-small mb-0">Account Number</p>
        <p className="text-normal text-sm m-0">{CardNumberFormat(asset?.number || '9999000000000456')}</p>
        <WebbDividerSmall />
        
        <p className="text-small mb-0">Account Status</p>
        <p className="text-normal text-sm m-0">{asset?.active ? 'Active' : 'Inactive'}</p>
      </div>

    </div>

    <WebbDividerMedium />
    <div className="text-small mt-1 text-color-lite">
      {asset?.item || '******'}
    </div>

    <WebbDividerSmall />
    <div className="back-color-wite p-3 rounded-xd border d-none">
      <p className="text-small m-0">Account Balance</p>
      <p className="m-0">
        <span className="text-lead text-bold">{NumberFormat(balance?.number/1000000 ||'0','', '2')}</span>
        <span className="ms-1"></span>
        <span className="text-small">{balance?.ticker || '***'}</span>
      </p>
    </div>

  </>

  )
}