// main
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import ContentFormat from '../content/webz/content-format-xv'
import WebbHeader from '../content/webz/webb-header-navs-xv'

import WebbIcon from '../content/webx/webb-icon'
import UserAvatar from '../content/webx/user-avatar'
import WebbDateTime from '../content/webx/webb-datetime'

import WebbDividerMedium from '../content/webx/webb-divider-md'
import WebbDividerSmall from '../content/webx/webb-divider-sm'
import HeaderSectionDark from '../content/webx/head-section-dark'

import { GetLocalBusiness, GetLocalUser } from '../services/srvc-auth-local'
import { GetUserForm } from '../services/srvc-utilities'

import VaultSelectModule from '../content/vault/vault-select'
import VaultActionsUserModule from '../content/vault/vault-actions-user'
import FinanceDocumentListModule from '../content/document/document-list-finance'
import MenuButtons from '../content/webx/menu-buttons'
import WebbMenuMobile from '../content/webz/webb-menu-xv'
import TeamInfocardHomeModule from '../content/user/team-infocard-home'
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import FloatButton from '../content/float/float-button'


export default function VaultFinance () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  

  const metadata = {
    name: 'Vault',
    banner: { link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'}
  }

  
  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: "xtra", show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`/${asset.role}/home`}>
                <WebbIcon data={{ color: "text-color-main", size: "text-icon-md" }}/>
              </Link>
            </>
          ),
          data: (
            <>
              {" "}
              <WebbHeader />{" "}
            </>
          ),
          footer: (
            <>
              {" "}
              <UserAvatar />{" "}
            </>
          ),
        }}
        sidebar={{
          header: (
            <>
              {" "}
              <p className="text-normal text-bold m-0">{metadata.name}</p>{" "}
            </>
          ),
          data: (
            <>
              <VaultSelectModule />
            </>
          ),
          footer: (
            <>
              <WebbDateTime />
            </>
          ),
        }}
        content={{
          header: (
            <>
              {" "}
              <div className="d-flex justify-content-between justify-content-md-start align-items-start">
                <div className="d-flex gap-3">
                  <i
                    data-bs-toggle="offcanvas"
                    href="#offcanvasExample"
                    role="button"
                    aria-controls="offcanvasExample"
                    className="bx bx-menu d-block d-md-none"
                    style={{ fontSize: "25px" }}
                  ></i>
                  <div
                    className="offcanvas offcanvas-start w-75 bg-body-tertiary"
                    tabIndex="-1"
                    id="offcanvasExample"
                    aria-labelledby="offcanvasExampleLabel"
                  >
                    <div className="offcanvas-header">
                      <h6
                        className="offcanvas-title"
                        id="offcanvasExampleLabel"
                      >
                        Contrato
                      </h6>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div
                      className="offcanvas-body d-flex flex-column gap-1"
                      style={{ position: "relative" }}
                    >
                      <TeamInfocardHomeModule />
                      
                      <WebbMenuMobile />
                      <div
                        style={{
                          position: "absolute",
                          bottom: "15px",
                          width: "88%",
                        }}
                      >
                        <MenuButtons />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="m-0">Vault - Documents</p>{" "}
                </div>
                <div
                  className="d-block d-md-none"
                  style={{ position: "relative", bottom: "6px" }}
                >
                  <UserAvatar />
                </div>
              </div>{" "}
            </>
          ),
          data: (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <HeaderSectionDark data={{ name: "Documents" }} />
                <div
                  className="d-flex align-items-center d-md-none gap-1 text-small cursor mb-3"
                  onClick={handleShow}
                >
                  Filter
                  <i
                    className="bx bx-slider-alt me-2"
                    style={{ fontSize: "14px" }}
                  ></i>
                </div>
              </div>
              <div>
                <Modal className="px-5 pt-5" show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Vault</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <VaultSelectModule />
                  </Modal.Body>
                </Modal>
              </div>
              <FinanceDocumentListModule />
              {
                usxx == "team"?
                <>
                <FloatButton action={VaultActionsUserModule} />
                </>
                :
                <>
                </>
              }
            </>
          ),
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: (
            <>
             {
              usxx == "team"?
              <>
               <VaultActionsUserModule />
              </>
              :
              <>
              </>
              }
              
            </>
          ),
          footer: <></>,
        }}
      />
    </>
  );
}
