// content
import { useEffect, useState } from "react";

import { GetUserForm, NumberFormat } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import WebbDividerMedium from '../../content/webx/webb-divider-md'
import WebbDividerSmall from '../../content/webx/webb-divider-sm'

import { AssetsDetails, UnitsDetails } from "../../services/srvc-assets-realm";

import media from '../../data.media/contrato-cards.png'
import { UnitsStatistics } from "../../services/srvc-tokens-realm";

export default function SharesChartLineModule() {

  const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [allocated, setAllocated] = useState("0")
  const [unit, setUnit] = useState()


  useEffect(() => {
    if (asset) {
      setLoader(true);
      const fetchData = async () => {
        setLoader(true);
        var res = await UnitsDetails({ data: { item: asset.item }, srvc: "" });
        if (res.stat) {
          setUnit(res.data)
          var unitstat = await UnitsStatistics({data: {unit : res.data.item, user: asset.item}, srvc:''})
          // 
          // console.log(unitstat)
          setData(unitstat.data)
          var totalsale = parseFloat(unitstat.data.stat.totalSale)
          var totalmint = parseFloat(unitstat.data.stat.totalMint)
          
          if (!isNaN(totalsale) && !isNaN(totalmint) && totalmint !== 0) {
            var salePercentage = (totalsale / totalmint) * 100;
            setAllocated(salePercentage)
           
          } else {
            // console.log("Invalid values for totalsale or totalmint.");
          }
        }


        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);
  // console.log(data)

  if (loader) {
    return <>Please Wait...</>;
  }

  return (
    <>
      <div className="">
        <p className="text-bold">Equity Allocation Overview</p>
      </div>

      <div className="back-color-wite border rounded-xd p-3">
        
        <div className="d-flex justify-content-between">
          <div className="w-75" >
            <p className="text-normal text-bold mb-1">{unit?.meta?.name || '***'}</p>
            <p className="m-0">{'ID: '}{unit?.webx?.number || '***'}</p>
          </div>
          <div className="text-end">
            <img src={media} className="w-25 rounded d-none"></img>
          </div>
        </div>

        <div className={data?.stat?.totalMint > 0 ? '' : 'd-none'}>
          <WebbDividerSmall />
          <WebbDividerSmall />
          <div className="d-flex justify-content-between">
            <div className="">
              Allocated
            </div>
            <div className="text-end">
              <p className="m-0">
                <span className="text-lead m-0">{NumberFormat(data?.stat?.totalSale || 0, "w")}</span>
                <span className=""></span>
              </p>
            </div>
          </div>

          <div className="progress rounded-md mt-2 mb-2" style={{ height: "20px", backgroundColor:'#ECF1F4' }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${allocated}%`, backgroundColor: "green" }}
              aria-valuenow={'50'}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>

          <div className="d-flex justify-content-between mt-1">
            <div className="">
              <p className="m-0">
                Total
              </p>
            </div>
            <div className="text-end">
              <p className="text-normal m-0">
                {NumberFormat(data?.stat?.totalMint || 0, "w")}
              </p>
            </div>
          </div>
        </div>
        
      </div>

      <div className={data?.stat?.totalMint > 0 ? 'd-none' : ''}>
        <WebbDividerSmall />
        <div className="p-3 back-color-wite border rounded-xd">
          <p className="text-color-error m-0 d-none">You have 0 Units</p>
          <a href='http://localhost:3000/team/shares/mint'>Click here to Create Units</a>
        </div>
      </div>      
    </>
  );
}
