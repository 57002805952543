// network
import { useEffect, useState } from "react";

import { GetUserForm, NumberFormat } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import {
  TokensListUser,
  UnitsStatistics,
} from "../../services/srvc-tokens-realm";
import { AssetsDetails, UnitsDetails } from "../../services/srvc-assets-realm";

export default function SharesTableTeamModule() {
  const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [sale, setSale] = useState(0);

  const colors = [
    { type: "Founders", color: "#007500" },
    { type: "Investors", color: "#00A300" },
    { type: "Mentors", color: "#00D100" },
    { type: "Others", color: "#00FF00" },
    { type: "NotAllocated", color: "#AAB8C2" },
  ];

     useEffect(() => {
       if (asset) {
         
         const fetchData = async () => {
          setLoader(true);

           var res = await UnitsDetails({
             data: { item: asset.item },
             srvc: "",
           });
          //  console.log(res.data)
           var unitstat = await UnitsStatistics({data: {unit : res.data.item, user: asset.item}, srvc:''})
           if (unitstat.stat) {
             setSale(unitstat?.data?.stat?.totalMint || "0");

             const listx = await TokensListUser({
               data: { asset: res.data.asset.item },
               srvc: "",
             });

             const list = listx?.data?.list.filter(x => x.user.item !== asset.item  );
            //  console.log(list)
             const founderslist = list
               .filter((item) => item.user.type === "Founders")
               .sort((a, b) => {
                 return a.user.name.localeCompare(b.user.name);
               });
             const Investorslist = list
               .filter((item) => item.user.type === "Investors")
               .sort((a, b) => {
                 return a.user.name.localeCompare(b.user.name);
               });
             const Mentorslist = list
               .filter((item) => item.user.type === "Mentors")
               .sort((a, b) => {
                 return a.user.name.localeCompare(b.user.name);
               });

             const Otherslist = list
               .filter((item) => !["Mentors","Founders","Investors" ].includes(item.user.type)  && item.user.item !== asset.item  )
               .sort((a, b) => {
                 return a.user.name.localeCompare(b.user.name);
               });

             const Creatorlist = list
               .filter((person) => person.user.item == asset.item)
               .sort((a, b) => {
                 return a.user.name.localeCompare(b.user.name);
               });

             const filteredList = [
               ...founderslist,
               ...Investorslist,
               ...Mentorslist,
               ...Otherslist,
               ...Creatorlist,
             ];

             setData(filteredList || []);
           }


           setLoader(false);
         };
         fetchData();
       } else {
       }
     }, []);

  
    //console.log(data)

  if (loader) {
    return <>
      <div className="">
        <p className="text-bold">Equity Allocation Table</p>
      </div>

      <div className="back-color-wite border rounded-wd p-3">
        <p className="m-0">Please Wait...</p>
      </div>
    </>;
  }

  return (
    <>
      <div className="">
        <p className="text-bold">Equity Allocation Table</p>
      </div>

      <div className={data.length == 0 ? "back-color-wite border rounded-wd p-3" : "d-none"}>
        <p className="m-0">{data.length > 0 ? data.length : "No Equity Allocation"}</p>
      </div>

      <div
        className={data.length > 0 ? "back-color-wite border rounded-wd " : "d-none"}
      >
        <div className="p-1 border-bottom">
          <div className={`d-flex cursor p-2 rounded-wd`}>
            <div className="" style={{ width: "60%" }}>
              <p className={`m-0 p-0 text-bold`}>{"Name"}</p>
            </div>
            <div className="text-end d-none" style={{ width: "20%" }}>
              <p className={`m-0 p-0 text-bold`}>{"Rate"}</p>
            </div>
            <div className="text-end" style={{ width: "20%" }}>
              <p className={`m-0 p-0 text-bold`}>{"Shares"}</p>
            </div>
            <div className="text-end" style={{ width: "20%" }}>
              <p className={`m-0 p-0 text-bold`}>{"Holding"}</p>
            </div>
          </div>
        </div>

        {data &&
          data.length > 0 &&
          data.map((item, i) => (
            <div className="">
              <div
                className={`d-flex cursor p-3 rounded-none hilite ${
                  i < data.length - 1 ? "" : ""
                }`}
                key={i}
              >
                <div className=" d-none">
                  <span className="text-small">
                    <i className={`m-0 p-0 small bx bxs-circle`}></i>
                  </span>
                </div>

                <div className="" style={{ width: "60%" }}>
                  <p className={`m-0 p-0 text-bold `}>
                    <i
                      className={`m-0 p-0 me-1 small bx bxs-circle`}
                      style={{
                        color:
                          colors.find((x) => x.type == item.user.type)?.color ||
                          "#00FF00",
                      }}
                    ></i>
                    {item.user.name}
                  </p>
                </div>

                <div className="d-none" style={{ width: "20%" }}>
                  <p className={`m-0 p-0 text-end`}>
                    {item?.rate?.number || "0"}
                  </p>
                </div>

                <div className="" style={{ width: "20%" }}>
                  <p className={`m-0 p-0 text-end`}>
                      {item?.acbx?.mint - item?.acbx.sale - item?.acbx?.burn}
                    {/* {NumberFormat(item?.balance?.number || 0, "w")} */}
                  </p>
                </div>

                <div className="" style={{ width: "20%" }}>
                  <p className={`m-0 p-0 text-end`}>
                    <span>
                      {(((item?.acbx?.mint - item?.acbx.sale - item?.acbx?.burn) / sale) * 100).toFixed(2) ||
                        "0.00"}
                    </span>
                    <span>{"%"}</span>
                  </p>
                </div>
              </div>
              <div
                className={data.length > i + 1 ? "border-bottom" : "d-none"}
              ></div>
            </div>
          ))}
      </div>
    </>
  );
}
