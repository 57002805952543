// content
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./account.css"

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import { AccountsList } from "../../services/srvc-accounts-realm";
// import { AccountsOnboardStatus } from "../../services/srvc-accounts-realm";

import list from '../../data.static/data-accounts.json'

export default function UserAccountsListModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState(list.data.filter(x=>x.actv))

  useEffect( () => {
    if (asset){

      const fetchData = async() => {
        setLoader(true);

        const result = await AccountsList({data: {user: asset.item}})
        // console.log(result)

        if (result.stat) {
          var list = result.data.list
         
          for(var i=0; i<list.length ; i++)
          {
            var key = list[i]?.meta?.sort
            var updates = {active: true,  account:{number: list[i]?.account?.number, status:true}}
           
            setData(data => 
                  data.map(obj => 
                    obj["sort"] === key 
                      ? { ...obj, ...updates } 
                      : obj
                  )
                );
                
                // console.log(data)
          }
        }
        
        setLoader(false);
      }
      fetchData()
    } else {}
  },[]);

  const handleClick = (item) => {
    if(item.active==true){}
    else navigate(`/${asset.role}/${item.link}`)
  }


  if (loader) return <><div className="mx-3 text-color-tone">Please Wait...</div></>
  if (!loader && data && data.length === 0) return <>
    <div className="mx-3">No Linked Accounts</div>
  </>

  return (
    <>
      {/* data */}
      <div className="back-color-wite rounded-xd border">
        {data &&
          data.map((item, i) => (
            <div key={i}>
              <div className={`d-flex px-3 mt-3 mb-3`}>
                <div className="">
                  <p className="m-0 ">
                    <i
                      className={`bx bxs-${
                        item.account?.status ? "check" : "error"
                      }-circle ${
                        item.account?.status
                          ? "text-color-success"
                          : "text-color-error"
                      }`}
                    ></i>
                  </p>
                </div>

                <div className="ms-2" style={{ maxWidth: "60%" }}>
                  <p className="m-0 text-sm">
                    <span className="text-color-success text-bold">
                      {item?.meta?.name || "******"}
                    </span>
                  </p>
                  <p className="m-0 text-wrap">
                    <span className="text-small text-wrap">
                      {item?.meta?.memo || "******"}
                    </span>
                  </p>
                  <p className="text-small m-0 text-truncate">
                    <span
                      className="text-sm textWidth"
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        // maxWidth:"500px"
                        // fontSize: "16px",
                      }}
                    >
                      Number: {item?.account?.number || "******"}
                    </span>
                  </p>
                </div>

                <div className="ms-auto text-end text-color-wite">
                  <div className="mb-1"></div>
                  <span
                    className={`p-2 px-3 back-color-success rounded-xx text-small align-middle ${
                      item.active ? "" : "d-none"
                    }`}
                  >
                    {"Linked"}
                  </span>
                  <span
                    className={`p-2 px-3 back-color-error rounded-xx text-small align-middle cursor hidark   ${
                      item.active ? "d-none" : ""
                    }`}
                    onClick={() => handleClick(item)}
                  >
                    {"Pending"}
                  </span>
                </div>
              </div>
              <div className={i < data.length - 1 ? "border-bottom" : ""}></div>
            </div>
          ))}
      </div>
    </>
  );
}