// init
import axios from "axios";
import { decrypt, encrypt } from "./srvc-encr-node";
// const base = 'https://ap-south-1.aws.data.mongodb-api.com/app/miniland-transfers-baommbt/endpoint'
const base =
  "https://ap-southeast-1.aws.data.mongodb-api.com/app/contrato-srvc-kcxltrc/endpoint";

// -----------------

export const TransfersList = async (item) => {
  const basx = base + "/transfers/funds/list";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};

export const TransfersAccountCredit = async (item) => {
  const basx = base + "/transfers/funds/account/credit";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};

export const TransfersAccountDebit = async (item) => {
  const basx = base + "/transfers/funds/account/debit";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};

export const TransfersCreate = async (item) => {
  const basx = base + "/transfers/funds/create";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};

export const TransfersDetails = async (item) => {
  const basx = base + "/transfers/funds/details";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};

export const TransfersFundsSubmit = async (item) => {
  const basx = base + "/transfers/funds/submit";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};

export const TransfersFundsDecline = async (item) => {
  const basx = base + "/transfers/funds/decline";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};