// documents
import { useEffect, useState } from "react";

import WebbDividerSmall from "./webb-divider-sm";
import WebbDividerMedium from "./webb-divider-md";
import ListNoData from "./list-nodata";


export default function DocumentMembersSet(props) {

  const list = props.data
  const loader = props.load

  // console.log(list)

  const [usrx, setUser] = useState('');
  const [data, setData] = useState([]);
  const [form, setForm] = useState(false);

  // form validation
  useEffect( () => {
    // if (usrx === '') setData([])
    // else setData(list.filter(item => item.name.toLowerCase().includes(usrx.toLowerCase())))
    setData(list)
  },[usrx]);

  const handleCallback = (member) => {
    // console.log(member)
    props.item(member);
    setData([])
    setUser('')
  }

  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  if (list.length===0) return (<> <ListNoData /> </>)

  return (
  <>
    
    {/* <div className="mb-3">
      <div className="form-group">
        <label className="form-label small">Search Contact</label>
        <input type="text" className="form-control rounded-none"
          style={{fontSize:'0.9rem', height:'2.7rem'}}
          value={usrx}
          onChange={({ target }) => {setUser(target.value);}}
          placeholder="Contact Name">
        </input>
      </div>
    </div> */}

    <div className={`border back-color-wite `}>
    {data && data.length > 0 && data.map((item, i) => ( i< 6 ?
      <div className="hilite" key={i}>
      <div className="d-flex p-3">
        <div className="">
          <p className="fw-bold m-0 p-0 text-sm">{item?.meta?.name || '******'}</p>
          <p className="small m-0 p-0">{item?.coms[0]?.nmbr || '******'}</p>
        </div>
        <div className="ms-auto text-end" style={{width:'15%'}}>
          <button className="btn rounded-circle back-color-lite m-0 p-0  hidark"
            style={{width:'2.4rem', height:'2.4rem'}}
            onClick={() => handleCallback(item)}  
          >
           <i className="bi-plus"></i>
          </button>
        </div>
      </div>
      <div className={`border-bottom ${i < data.length-1 ? '': 'd-none'}`}></div>
      </div>
      :''))}
    </div>

    <WebbDividerSmall />

  </>
  )
}